import { useState } from "react";
import {
  invokeUserRefetch,
  walletData,
} from "../../../store/slices/storeSlice";
import { endpoints } from "../../../hooks/endpoints";
import { useAppDispatch } from "../../../store/hooks";

const WalletPurchase = ({ lessonData, isMobile }) => {
  const dispatch = useAppDispatch();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const handlePurchase = () => {
    setIsPending(true);
    fetch(
      endpoints.walletPurchase +
        "?lesson=" +
        lessonData.lesson_id +
        "&ismobile=" +
        isMobile,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem(
            `${window.location.hostname}_user`
          )
            ? "Bearer" +
              JSON.parse(
                localStorage.getItem(`${window.location.hostname}_user`)
              ).token
            : null,
        },
        // signal: abortCont.signal
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("this is result: ", result);
        if (result !== "لا يوجد رصيد كافي") {
          dispatch(walletData(result.wallet_credit));
          dispatch(invokeUserRefetch());
        } else {
          setError(result);
        }
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err);
        setError("حدث خطأ");
        setIsPending(false);
      });
  };

  return (
    <div className="d-block mb-4">
      {!isPending && (
        <button className="btn ea-btn m-auto" onClick={handlePurchase}>
          شراء بالرصيد
        </button>
      )}
      {isPending && (
        <button className="btn ea-btn m-auto" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm mx-2"
            role="status"
            aria-hidden="true"
          ></span>
          شراء بالرصيد...
        </button>
      )}
      {error && (
        <div className="d-flex">
          <div className="mx-auto my-2 alert alert-danger w-50" role="alert">
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletPurchase;
