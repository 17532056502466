import { ErrorTags, reportAppError } from "../../../errorReporter";
import { endpoints } from "../../../hooks/endpoints";
import { ResultI } from "../../../hooks/useAssessmentController";
import { getToken } from "../../../hooks/utils";
import { store } from "../../../store/store";
import { LessonDataI } from "../typesAndEnums";

export const handleSubmitHwToServer = async ({
  lessonData,
  result,
  setSubmitError,
  setIsSubmitPending,
}: {
  lessonData: LessonDataI;
  result: ResultI;
  setSubmitError: (x: boolean) => void;
  setIsSubmitPending: (x: boolean) => void;
}) => {
  try {
    setSubmitError(false);
    setIsSubmitPending(true);
    const reduxStore = store.getState().store;
    const user = reduxStore.userProfileData;
    const questionsIds = Object.keys(result).filter(
      (key) => !["score", "maxScore"].includes(key)
    );
    const answers = questionsIds
      .map((qID) => `${qID}-${result[qID].answer}-${result[qID].correct}`)
      .join(",");

    await fetch(endpoints.submitHomework, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify({
        title: `${lessonData.hw_id}-${user.display_name}`,
        homework_id: lessonData.hw_id,
        homework: lessonData.hw_id,
        grade: result.score,
        raw_data: JSON.stringify(result),
        answers: answers,
        lesson_id: lessonData.lesson_id,
      }),
    });
  } catch (error) {
    reportAppError({
      e: error,
      info: "error while submitting a homework",
      errorTag: ErrorTags.LessonScreen,
    });
    setSubmitError(true);
  } finally {
    setIsSubmitPending(false);
  }
};
