import React from 'react'

const FileUploadProgressBar = ({fileProgress}) => {
  return (
        <>
          <div className="m-1 w-100" style={{ textAlign: "center" }}>
            <p>جاري رفع الملف</p>
          </div>
          <div
            className="progress"
            role="progressbar"
            aria-label="Success example"
            aria-valuenow={fileProgress}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar bg-ea-primary text-white"
              style={{ width: `${fileProgress}%` }}
            >
              {fileProgress}%
            </div>
          </div>
        </>
  )
}

export default FileUploadProgressBar
