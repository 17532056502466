import { useState, useEffect } from "react";

const QuizTimer = ({ seconds, handleSubmitQuiz }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(seconds);

  useEffect(() => {
    if (remainingSeconds > 0) {
      const timerId = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(timerId);
      };
    } else {
      handleSubmitQuiz();
    }
  }, [remainingSeconds]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div
      className="mt-3 mx-4 alert alert-warning d-flex rounded-ea border-warning"
      role="alert"
    >
      <h5 className="m-auto d-inline">
        <p className="m-auto d-inline">
          باقي من الوقت {formatTime(remainingSeconds)}
        </p>
      </h5>
    </div>
  );
};

export default QuizTimer;
