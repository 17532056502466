import ASSESSMENTIMG from "../../../assets/images/exam-finished.png";

const AssessmentResultMsg = ({
  title,
  score,
  maxScore,
  percentage,
}: {
  title: string;
  score: number;
  maxScore: number;
  percentage: number;
}) => {
  return (
    <div className="p-3">
      <div className="d-flex">
        <img
          className="image-fluid w-50 mx-auto"
          src={ASSESSMENTIMG}
          alt="quiz score"
        />
      </div>
      <div className="d-flex flex-column mb-3">
        <h3 className="mx-auto my-2">{title}</h3>
        <p className="fw-bold mx-auto my-2 fs-5">{`${maxScore} / ${score}`}</p>
      </div>
      <div className="d-flex">
        <div
          className="progress rounded-ea responsive-quiz-grade mx-auto"
          style={{ height: "1.5rem" }}
        >
          <div
            className="progress-bar rounded-ea"
            role={"progressbar"}
            style={{ width: `${percentage}%`, height: "1.5rem" }}
            aria-valuenow={percentage}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentResultMsg;
