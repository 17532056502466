import Purchase from "../../../orders/Purchase";
import { LessonDataI } from "../../../quiz/typesAndEnums";
import VideosMap from "../../../video/VideosMap";
import useVideoHandler from "../hooks/useVideoHandler";
import useCheckIsMobile from "../utils/useCheckIsMobile";

const VideoContent = ({ lessonData }: { lessonData: LessonDataI }) => {
  const {
    videos,
    isExpired,
    isViewsExpired,
    isDateExpired,
    videoExist,
    files,
    expirtDate,
    isProtected,
    getVideoID,
    handleVideoView,
  } = useVideoHandler({ lessonData });

  const isMobile = useCheckIsMobile();

  if (!videoExist) {
    return (
      <div className="p-5 d-flex">
        <h2 className="m-auto">لا يوجد فيديو</h2>
      </div>
    );
  }

  if (isExpired) {
    return (
      <div className="alert alert-danger m-5 rounded-ea" role="alert">
        {`${
          isDateExpired
            ? "إنتهي الوقت المسموح لمشاهدة الحصة ، "
            : isViewsExpired
            ? "إنتهت مشاهداتك ، "
            : ""
        }يمكنك فتح الحصة مرة اخرى`}
        <Purchase
          lessonData={lessonData}
          isMobile={isMobile}
          isRepurchase={true}
        />
      </div>
    );
  }

  return (
    <VideosMap
      videos={videos}
      lessonData={lessonData}
      expirtDate={expirtDate}
      files={files}
      isProtected={isProtected}
      getVideoID={getVideoID}
      handleVideoView={handleVideoView}
    />
  );
};

export default VideoContent;
