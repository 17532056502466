export const getToken = () => {
  return localStorage.getItem(`${window.location.hostname}_user`)
    ? "Bearer " +
        JSON.parse(localStorage.getItem(`${window.location.hostname}_user`))
          .token
    : null;
};

export const getUID = () => {
  return localStorage.getItem(`${window.location.hostname}_user`)
    ? JSON.parse(localStorage.getItem(`${window.location.hostname}_user`)).uid
    : null;
};

// export const getFileUrl = async (text) => {
//   if (!text) return text;
//   if (text.indexOf("amazonaws") !== -1) return text;
//   else {
//     const refObj = ref(storage, text);
//     const url = await getDownloadURL(refObj);
//     console.log("safafsfw", url);
//     if (url) return url;
//     else return text;
//   }
// };

export const getFileUrl = (url: string) => {
  // return encodeURIComponent(url.split("&token=")[0]);
  return url.split("&token=")[0];
};

export const isPhoneValid = (phoneNumber) => {
  let isValidStart = /^01/.test(phoneNumber);
  if (!isValidStart) {
    return {
      isValid: false,
      message: "يجب ان يبدأ رقم الهاتف بـ 01",
    };
  }

  let isAllNumbers = /^\d+$/.test(phoneNumber);
  if (!isAllNumbers) {
    return {
      isValid: false,
      message: "يجب ان يحتوي رقم الهاتف علي ارقام فقط",
    };
  }

  let isValidLength = /^\d{11}$/.test(phoneNumber);
  if (!isValidLength) {
    return {
      isValid: false,
      message: "يجب ان يحتوي رقم الهاتف علي 11 رقم فقط",
    };
  }

  return {
    isValid: true,
    message: null,
  };
};

export const isNameValid = (name) => {
  let isAllLettersAndAtLeast15Chars = /^[\u0600-\u06FF ]{15,}$/.test(name);
  if (name?.split(" ").length < 4) {
    return {
      isValid: false,
      message: "يجب ان يكون الإسم رباعي",
    };
  }
  if (!isAllLettersAndAtLeast15Chars) {
    return {
      isValid: false,
      message:
        "يجب ان يحتوي الاسم الرباعي علي حروف عربية فقط ويجب ان يكون علي الاقل 15 حرف",
    };
  }

  return {
    isValid: true,
    message: null,
  };
};

export const isEmailValid = (email) => {
  let isValid =
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,4})$/.test(email);
  if (!isValid) {
    return {
      isValid: false,
      message: "هذا الإيميل غير صحيح",
    };
  }

  return {
    isValid: true,
    message: null,
  };
};

export const textToUnicode = (text) => {
  const unicodeCodePoints = [];
  for (let i = 0; i < text?.length; i++) {
    unicodeCodePoints.push(text.charCodeAt(i));
  }
  return unicodeCodePoints;
};

export const unicodeToText = (codePoints) => {
  return codePoints.map((code) => String.fromCharCode(code)).join("");
};

export const genRandID = (count = 1) => {
  const numbers = [];
  for (let i = 0; i < count; i++) {
    numbers.push(Math.floor(Math.random() * 10000)); // Generates a random number between 0 and 9999
  }
  return numbers.join("");
};

export function sanitizeUrl(url) {
  // Remove invalid characters (e.g., spaces, special characters)
  const sanitizedUrl = url.replace(/[^a-zA-Z0-9._-]/g, "");

  // Remove leading and trailing slashes
  return sanitizedUrl.replace(/^\/|\/$/g, "");
}

export const reorderForPagination = ({
  arr,
  currentPage,
  pageCount,
}: {
  arr: any[];
  currentPage: number;
  pageCount: number;
}) => {
  return arr.slice((currentPage - 1) * pageCount, currentPage * pageCount);
};

export const dateFormatter = (dateStr: string) => {
  // Define the Arabic and English numeral mappings
  const arabicNumbers = "٠١٢٣٤٥٦٧٨٩".split("");
  const englishNumbers = "0123456789".split("");

  const date = `${new Date(dateStr)
    .toLocaleString("ar-EG", {
      day: "2-digit",
      month: "long",
    })
    .replace(
      /[٠١٢٣٤٥٦٧٨٩]/g,
      (x) => englishNumbers[arabicNumbers.indexOf(x)]
    )} - ${new Date(dateStr)
    .toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replaceAll("AM", "ص")
    .replaceAll("PM", "م")}`;

  return date;
};
