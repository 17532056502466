const QuizStartMsg = ({
  quizDuration,
  handleStartQuiz,
}: {
  quizDuration: number;
  handleStartQuiz: () => void;
}) => {
  return (
    <div className="p-5">
      <div className="w-100 d-flex mb-2">
        <p className="d-inline m-auto">المدة : {quizDuration / 60} دقيقة</p>
      </div>
      <div className="w-100 d-flex">
        <button
          className="btn ea-btn m-auto rounded-ea px-4 py-2"
          onClick={handleStartQuiz}
        >
          إبدأ الكويز
        </button>
      </div>
    </div>
  );
};

export default QuizStartMsg;
