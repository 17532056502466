function Footer() {
  const waNumber = "201012376009";
  return (
    <>
      <a
        href={`https://api.whatsapp.com/send?phone=${waNumber}`}
        className="wa-float pt-2"
        target="_blank"
      >
        <i className="bi bi-whatsapp m-auto"></i>
      </a>
      <div className="footer d-flex p-2">
        <p className="m-auto h6">Copyright © 2024, All Rights Reserved, Elite Academy</p>
      </div>
    </>
  );
}

export default Footer;
