import QuizTimer from "./QuizTimer";
import useQuizHandler from "./hooks/useQuizHandler";
import AssessmentResultMsg from "./components/AssessmentResultMsg";
import QuizStartMsg from "./components/QuizStartMsg";
import AssessmentQuestions from "./components/AssessmentQuestions";
import AssessmentSubmitBtn from "./components/AssessmentSubmitBtn";
import {
  AssessmentMode,
  AssessmentResultTextType,
  AssessmentSubmitBtnType,
  LessonDataI,
} from "./typesAndEnums";

const Quiz = ({ lessonData }: { lessonData: LessonDataI }) => {
  const {
    score,
    maxScore,
    percentage,
    isEnded,
    isStarted,
    isExpired,
    remainingTime,
    questions,
    isSubmitPending,
    submitError,
    handleStartQuiz,
    handleSubmitQuiz,
  } = useQuizHandler({ lessonData });

  if (!lessonData.quiz_id)
    return (
      <div className="p-5 d-flex">
        <h2 className="m-auto">لا يوجد كويز</h2>
      </div>
    );

  if (isExpired)
    return (
      <div className="alert alert-danger m-5 rounded-ea" role="alert">
        إنتهى الوقت المسموح لدخول الكويز , تواصل مع المدرس
      </div>
    );

  return (
    <div>
      {isEnded && (
        <AssessmentResultMsg
          title={AssessmentResultTextType.QUIZ}
          score={score}
          maxScore={maxScore}
          percentage={percentage}
        />
      )}

      {!isStarted && !isEnded && (
        <QuizStartMsg
          quizDuration={lessonData.quiz_duration}
          handleStartQuiz={handleStartQuiz}
        />
      )}

      {isStarted && !isEnded && (
        <QuizTimer
          handleSubmitQuiz={handleSubmitQuiz}
          seconds={remainingTime}
        />
      )}

      {(isStarted || isEnded) && (
        <form
          className="quiz-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitQuiz();
          }}
        >
          <AssessmentQuestions
            assessmentMode={AssessmentMode.QUIZ}
            assessmentId={lessonData?.quiz_id}
            questions={questions}
            isEnded={isEnded}
            mustAnswerAll={lessonData.must_answer_all}
            hideCorrectAnswers={lessonData.hide_correct_answers}
          />

          <AssessmentSubmitBtn
            isStarted={isStarted}
            isEnded={isEnded}
            isSubmitPending={isSubmitPending}
            btnText={AssessmentSubmitBtnType.QUIZ}
          />

          {submitError && (
            <div className="alert alert-danger m-3 rounded-ea" role="alert">
              حدث خطأ أثناء تسليم الامتحان , تواصل مع الدعم الفني
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default Quiz;
