import { useEffect, useState } from "react";

const AssessmentSubmitBtn = ({
  isEnded,
  isStarted,
  isSubmitPending,
  btnText,
}: {
  isEnded: boolean;
  isStarted: boolean;
  isSubmitPending: boolean;
  btnText: string;
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (isSubmitPending) setIsDisabled(true);
  }, [isSubmitPending]);
  return (
    <div className="d-flex py-2 px-3">
      {!isEnded && isStarted && !isSubmitPending && (
        <input
          type="submit"
          disabled={isDisabled}
          className="btn ea-btn rounded-ea w-100 mx-auto"
          value={btnText}
        />
      )}
      {isSubmitPending && (
        <button
          className="btn ea-btn rounded-ea w-100 mx-auto"
          type="button"
          disabled
        >
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {btnText}...
        </button>
      )}
    </div>
  );
};

export default AssessmentSubmitBtn;
