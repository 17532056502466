import Animation from "../../../Animation";
import { LessonDataI } from "../../../quiz/typesAndEnums";
import { LessonTabs } from "../hooks/useGetLesson";

const LessonNavigationTabs = ({
  lessonData,
  setCurrentContent,
  currentContent,
}: {
  lessonData: LessonDataI;
  setCurrentContent: (x: LessonTabs) => void;
  currentContent: LessonTabs;
}) => {
  if (!lessonData.video_exist) return "";

  return (
    <Animation>
      <ul className="nav nav-pills w-100 res-tabs px-4">
        <li
          className="nav-item m-auto my-1"
          role="presentation"
          style={{ width: "32%" }}
        >
          <button
            style={{ border: "2px solid #673DE6" }}
            className={`nav-link ea-toggle rounded-ea text-dark w-100 ps-1 pe-1 pt-2 pb-2 ${
              currentContent === LessonTabs.QUIZ && "active"
            }`}
            type="button"
            onClick={() => setCurrentContent(LessonTabs.QUIZ)}
          >
            كويز
          </button>
        </li>
        {!lessonData.quiz_required || lessonData.past_quiz_trials !== null ? (
          <li
            className="nav-item m-auto my-1"
            role="presentation"
            style={{ width: "32%" }}
          >
            <button
              style={{
                border: "2px solid #673DE6",
              }}
              className={`nav-link ea-toggle rounded-ea text-dark w-100 ps-1 pe-1 pt-2 pb-2 ${
                currentContent === LessonTabs.VIDEO && "active"
              }`}
              type="button"
              onClick={() => setCurrentContent(LessonTabs.VIDEO)}
            >
              فيديو
            </button>
          </li>
        ) : (
          <li
            className="nav-item m-auto my-1"
            role="presentation"
            style={{ width: "32%" }}
          >
            <button
              disabled={true}
              style={{
                border: "2px solid #ddd",
                backgroundColor: "#ddd",
              }}
              className="nav-link ea-toggle rounded-ea text-dark w-100 ps-1 pe-1 pt-2 pb-2"
              type="button"
            >
              فيديو ( يجب حل الكويز )
            </button>
          </li>
        )}
        <li
          className="nav-item m-auto my-1"
          role="presentation"
          style={{ width: "32%" }}
        >
          <button
            style={{ border: "2px solid #673DE6" }}
            className={`nav-link ea-toggle rounded-ea text-dark w-100 ps-1 pe-1 pt-2 pb-2 ${
              currentContent === LessonTabs.HOMEWORK && "active"
            }`}
            type="button"
            onClick={() => setCurrentContent(LessonTabs.HOMEWORK)}
          >
            واجب
          </button>
        </li>
      </ul>
    </Animation>
  );
};

export default LessonNavigationTabs;
