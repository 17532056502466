
const ProgressBar = ({
  playerId,
  totalTime,
  currentTime,
  updateTimeline,
}: {
  playerId: string;
  totalTime: number;
  currentTime: number;
  updateTimeline: (e) => void;
}) => {
  return (
    <div className="ea-timeline-container py-0 px-3">
      <input
        id={`timeline-${playerId}`}
        type="range"
        max={totalTime}
        min={0}
        step={0.25}
        // value={currentTime}
        className="ea-timeline w-100 form-range range"
        onChange={updateTimeline}
      />
    </div>
  );
};

export default ProgressBar;
