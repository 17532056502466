import { useRef } from "react";
import { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { endpoints } from "../../hooks/endpoints";

const PasswordReset = () => {
  const emailRef = useRef("");
  const resKeyRef = useRef("");
  const passRef = useRef("");
  const [msg, setMsg] = useState<string>("");
  const [stepOneComplete, setStepOneComplete] = useState(false);
  const [passwordHasBeenReset, setPasswordHasBeenReset] = useState(false);

  const submitEmail = (e) => {
    e.preventDefault();
    fetch(`${endpoints.getPassResetKey}?email=${emailRef.current.value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setMsg(result.data);
        setStepOneComplete(result.success);
      })
      .catch((error) => {
        setMsg(error.data);
        setStepOneComplete(error.success);
      });
  };

  const submitResKey = (e) => {
    e.preventDefault();
    setPasswordHasBeenReset(true);
    fetch(
      `${endpoints.changePassWithResetKey}?email=${emailRef.current.value}&new_pass=${passRef.current.value}&reset_key=${resKeyRef.current.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.json())
    .then((result) => {
      setMsg(result.data);
    })
    .catch((error) => {
      setMsg(error.data);
      setPasswordHasBeenReset(false);
      });
  };

  // const submitResKey = (e) => {
  //   e.preventDefault();
  //   fetch(
  //     `https://api.eliteacademyeg.com/pass-reset-with-key/?email=${emailRef.current.value}&new_pass=${passRef.current.value}&reset_key=${resKeyRef.current.value}`,
  //     {
  //       method: "GET",
  //       redirect: "follow",
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setMsg("تم تغيير كلمة السر");
  //       // console.log(result)
  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //       // setMsg("حدث خطأ")
  //       setMsg("تم تغيير كلمة السر");
  //     });
  // };

  return (
    <div className="p-5 bg-white" style={{ height: "88vh" }}>
      <div
        className="rounded-ea p-2 bg-light container w-50"
        style={{ border: "2px solid #673DE6" }}
      >
        <div className="d-flex my-2">
          <h2 className="m-auto text-ea-primary">إسترجاع كلمة السر</h2>
        </div>
        <div className="d-flex my-2">
          <form
            className="w-75 m-auto"
            style={{ display: stepOneComplete ? "none" : "" }}
            onSubmit={submitEmail}
          >
            <div className="form-floating my-2">
              <input
                required="required"
                type="email"
                ref={emailRef}
                className="form-control rounded-ea border-ea"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput" className="text-ea-primary">
                الإيميل
              </label>
            </div>
            <input
              type="submit"
              className="btn ea-btn rounded-ea w-100"
              value="طلب كود الإسترجاع"
            />
          </form>
          {stepOneComplete && (
            <form className="w-75 m-auto" onSubmit={submitResKey}>
              <div className="form-floating my-2">
                <input
                  required="required"
                  type="text"
                  ref={resKeyRef}
                  className="form-control rounded-ea border-ea"
                  id="floatingInput2"
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput" className="text-ea-primary">
                  رمز التغيير (هتلاقيه على الإيميل)
                </label>
              </div>
              <div className="form-floating my-2">
                <input
                  required="required"
                  type="password"
                  ref={passRef}
                  className="form-control rounded-ea border-ea"
                  id="floatingInput3"
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput" className="text-ea-primary">
                  كلمة السر الجديدة
                </label>
              </div>
              <input
                type="submit"
                className="btn ea-btn rounded-ea w-100"
                value="تغيير كلمة السر"
                disabled={passwordHasBeenReset}
              />
            </form>
          )}
        </div>
        <div className="d-flex">
          <Link
            to="/"
            className="m-auto text-ea-primary"
            style={{ textDecoration: "none" }}
          >
            الرجوع للصفحة الرئيسية
          </Link>
        </div>
        {msg && (
          <div className="d-flex my-2">
            <div
              className={`alert ${
                msg === "تم تغيير كلمة السر" ? "alert-success" : "alert-warning"
              } w-75 m-auto rounded-ea`}
              role="alert"
            >
              {String(msg)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
