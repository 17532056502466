import Animation from "../../../Animation";
import Sidebar from "./Sidebar";

const LessonPageSidebar = () => {
  return (
    <div className="col-md-4 col-12 pt-3 mt-0">
      <Animation>
        <Sidebar />
      </Animation>
    </div>
  );
};

export default LessonPageSidebar;
