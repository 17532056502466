import { useEffect, useRef } from "react";
import Spinner from "../Spinner";
import { useState } from "react";
import Animation from "../Animation";
import { getFileUrl, getToken, getUID, textToUnicode } from "../../hooks/utils";
import { endpoints } from "../../hooks/endpoints";
import QRCode from "react-qr-code";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../App";
import { invokeUserRefetch } from "../../store/slices/storeSlice";

const AccountPage = () => {
  const [passChangeStatus, setPassChangeStatus] = useState(null);
  const [dataChangeStatus, setDataChangeStatus] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();

  const oldPassRef = useRef();
  const newPassRef = useRef();

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setIsPending(true);
    fetch(endpoints.changePassword, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "POST",
      body: JSON.stringify({
        current_password: oldPassRef?.current?.value ?? "",
        new_password: newPassRef?.current?.value ?? "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("change_password", data);
        setPassChangeStatus(data);
        setIsPending(false);
      })
      .catch((err) => {
        console.log("change_password", err);
        setPassChangeStatus(err);
        setIsPending(false);
      });
  };

  const handleDataChange = (e) => {
    e.preventDefault();
    setIsPending(true);
    fetch(endpoints.updateUserInfo, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "POST",
      body: JSON.stringify({
        name: userData.display_name,
        email: userData.user_email,
        profile_pic: userData.profile_pic,
        city: userData.city,
        school: userData.school,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("change_data", data);
        setDataChangeStatus(data);
        setIsPending(false);
        dispatch(invokeUserRefetch());
      })
      .catch((err) => {
        console.log("change_data", err);
        setDataChangeStatus(err);
        setIsPending(false);
      });
  };

  const userInfo = useAppSelector((state) => state.store.userProfileData);

  useEffect(() => {
    console.log("asfwedwegew", userInfo);
    if (userInfo) setUserData(userInfo);
  }, [userInfo]);

  const handleUploadProfilePicFb = async (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    setImageLoading(true);

    try {
      const imageRef = ref(storage, `profile_pics-${getUID()}`);
      await uploadBytes(imageRef, e.target.files[0]);
      const url = await getDownloadURL(imageRef);
      setUserData((prev) => ({
        ...prev,
        profile_pic: getFileUrl(url),
      }));
    } catch (error) {
      alert("حدث خطأ");
    }
  };

  return (
    <Animation>
      <div className="container responsive-pages py-3">
        <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">حسابي</h2>
        <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow">
          {userData && (
            <Animation>
              <div className="m-3 row">
                <div
                  className={`col-md-6 col-12 text-center ${
                    imageLoading && "loading"
                  }`}
                >
                  <img
                    onLoad={() => setImageLoading(false)}
                    style={{
                      aspectRatio: 1,
                      width: "12rem",
                      border: "3px solid var(--primary-color)",
                      cursor: "pointer",
                    }}
                    src={decodeURIComponent(userData.profile_pic)}
                    className="rounded rounded-circle m-3"
                    alt="صورة شخصية"
                    onClick={() => {
                      document.getElementById("uploaded_profile_pic").click();
                    }}
                  />
                </div>
                <input
                  type="file"
                  className="d-none"
                  id="uploaded_profile_pic"
                  onChange={(e) => handleUploadProfilePicFb(e)}
                />
                <div className="col-md-6 col-12 text-center">
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                    }}
                    className="m-3 qrcode"
                    value={
                      JSON.stringify({
                        name: textToUnicode(userData.display_name),
                        group: textToUnicode(userData.group),
                        uid: getUID(),
                        portal: window.location.hostname,
                      }) ?? "No ID found"
                    }
                    // viewBox={`0 0 256 256`}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      الإسم رباعي
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.display_name}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.display_name}
                      onChange={(e) => {
                        setUserData((prev) => ({
                          ...prev,
                          display_name: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      المجموعة
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.group}
                      aria-label="group"
                      aria-describedby="basic-addon1"
                      value={userData.group}
                      contentEditable={false}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      تليفون
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.user_login}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.user_login}
                      contentEditable={false}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      إيميل
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.user_email}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.user_email}
                      onChange={(e) => {
                        setUserData((prev) => ({
                          ...prev,
                          user_email: e.target.value,
                        }));
                      }}
                      // contentEditable={false}
                      // disabled
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      تليفون الأب
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.father_phone}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.father_phone}
                      contentEditable={false}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      تليفون الأم
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.mother_phone}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.mother_phone}
                      contentEditable={false}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      المحافظة
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.city}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.city}
                      onChange={(e) => {
                        setUserData((prev) => ({
                          ...prev,
                          city: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      المدرسة
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.school}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.school}
                      onChange={(e) => {
                        setUserData((prev) => ({
                          ...prev,
                          school: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="btn ea-btn rounded-ea"
                  onClick={handleDataChange}
                  disabled={isPending}
                >
                  تحديث البيانات
                </button>
                {dataChangeStatus && (
                  <div className="d-flex">
                    <div
                      style={{
                        width: "30%",
                        minWidth: "150px",
                        textAlign: "center",
                      }}
                      className={`my-2 mx-auto d-inline alert alert-${
                        dataChangeStatus.success ? "success" : "danger"
                      }`}
                      role="alert"
                    >
                      {dataChangeStatus.success
                        ? "تم تغيير البيانات بنجاح"
                        : "حدث خطأ"}
                    </div>
                  </div>
                )}
                <div className="d-flex">
                  <p
                    className="text-ea-primary h5 fw-bold mx-auto my-2 rounded-ea p-2"
                    style={{
                      textAlign: "center",
                      width: "50%",
                      minWidth: "150px",
                    }}
                  >
                    تغيير كلمة السر
                  </p>
                </div>
                <form className="row" onSubmit={handlePasswordChange}>
                  <div className="col-md-6 col-12">
                    <div
                      className="container input-group mb-3"
                      style={{ justifyContent: "center" }}
                    >
                      <span className="input-group-text" id="basic-addon1">
                        كلمة السر القديمة
                      </span>
                      <input
                        ref={oldPassRef}
                        type="password"
                        className="form-control"
                        placeholder="كلمة السر القديمة"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div
                      className="container input-group mb-3"
                      style={{ justifyContent: "center" }}
                    >
                      <span className="input-group-text" id="basic-addon1">
                        كلمة السر الجديدة
                      </span>
                      <input
                        ref={newPassRef}
                        type="password"
                        className="form-control"
                        placeholder="كلمة السر الجديدة"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <input
                      style={{ width: "30%", minWidth: "150px" }}
                      className="btn ea-btn rounded-ea mx-auto"
                      type="submit"
                      value="تغيير"
                    />
                  </div>
                  {passChangeStatus && (
                    <div className="d-flex">
                      <div
                        style={{
                          width: "30%",
                          minWidth: "150px",
                          textAlign: "center",
                        }}
                        className={`my-2 mx-auto d-inline alert alert-${
                          passChangeStatus.success ? "success" : "danger"
                        }`}
                        role="alert"
                      >
                        {passChangeStatus.success
                          ? "تم تغيير كلمة السر"
                          : "البيانات خاطئة"}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </Animation>
          )}
          {error && <h2>يجب تسجيل الدخول اولا</h2>}
        </div>
        {isPending && <Spinner />}
      </div>
    </Animation>
  );
};

export default AccountPage;
