import Quiz from "../../../quiz/Quiz";
import Homework from "../../../quiz/Homework";
import VideoContent from "./VideoContent";
import Animation from "../../../Animation";
import { LessonTabs } from "../hooks/useGetLesson";

const LessonContent = ({ lessonData, currentContent }) => {
  return (
    <div className="tab-content">
      {/* QUIZ CONTENT */}
      {currentContent === LessonTabs.QUIZ && (
        <Animation>
          <Quiz lessonData={lessonData} />
        </Animation>
      )}

      {/* VIDEO CONTENT */}
      {currentContent === LessonTabs.VIDEO && (
        <Animation>
          <VideoContent lessonData={lessonData} />
        </Animation>
      )}

      {/* HOMEWORK CONTENT */}
      {currentContent === LessonTabs.HOMEWORK && (
        <Animation>
          <Homework lessonData={lessonData} />
        </Animation>
      )}
    </div>
  );
};

export default LessonContent;
