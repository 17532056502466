import Animation from "../../../Animation";

const LessonPageContainer = ({ children }) => {
  return (
    <Animation>
      <div className="lesson-page responsive-lesson bg-eee">
        <div className="row bg-eee d-flex">{children}</div>
      </div>
    </Animation>
  );
};

export default LessonPageContainer;
