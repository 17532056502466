import { useState } from "react";
import ChooseQuestionModal from "./ChooseQuestionModal";

const AddFromQuestionBank = ({
  questionNum,
  questionsImages,
  availableAnswers,
  setQuestionsImages,
  setAvailableAnswers,
  prefix,
}) => {
  const addQuestion = (question, questionNum) => {
    const imgUrl = document.getElementById(
      `${prefix}question_img_url_${questionNum}`
    );
    const qBody = document.getElementById(
      `${prefix}question_textarea_${questionNum}`
    );
    const ansCount = document.getElementById(
      `${prefix}question_ans_count_${questionNum}`
    );
    const ans = document.getElementById(`${prefix}question_ans_${questionNum}`);
    const weight = document.getElementById(
      `${prefix}question_weight_${questionNum}`
    );
    const qID = document.getElementById(`qid_${questionNum}`);
    console.log({ question });
    imgUrl.value = question.image_url;
    ansCount.value = question.answers_count;
    weight.value = question.weight;
    qID.value = question.id;
    qBody.value = question.content;
    
    setQuestionsImages({
      ...questionsImages,
      [`${prefix}question_img_url_${questionNum}`]: question.image_url,
    });
    setAvailableAnswers({
      ...availableAnswers,
      [`${prefix}question_ans_count_${questionNum}`]: question.answers_count,
    });
    setTimeout(() => {
      ans.value = question.correct_answer;
    }, 500);
  };

  return (
    <div className="my-2">
      <ChooseQuestionModal
        addQuestion={addQuestion}
        questionNum={questionNum}
      />
    </div>
  );
};

export default AddFromQuestionBank;
