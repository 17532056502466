
const ErrorMsg = () => {
  return (
    <div className="container text-center p-5 m-auto">
      <div className="alert alert-danger fw-bold rounded-ea w-100" role="alert">
        حدث خطأ، تواصل مع الدعم الفني
      </div>
    </div>
  );
};

export default ErrorMsg;
