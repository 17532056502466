import { ErrorTags, reportAppError } from "../../../../errorReporter";
import { endpoints } from "../../../../hooks/endpoints";
import { getToken } from "../../../../hooks/utils";
import { LessonDataI } from "../../../quiz/typesAndEnums";

export const fetchLesson = async (lesson: string) => {
  try {
    const res = await (
      await fetch(endpoints.lessonV2 + `?lesson=${lesson}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      })
    ).json();

    return res as LessonDataI;
  } catch (error) {
    const err = error as Error;
    reportAppError({
      e: err,
      info: "error while fetching a single lesson data",
      errorTag: ErrorTags.LessonScreen,
    });
    return null;
  }
};
