import { useEffect, useRef, useState } from "react";
import Animation from "../Animation";
import { endpoints } from "../../hooks/endpoints";
import { getToken, reorderForPagination } from "../../hooks/utils";
import { useAppSelector } from "../../store/hooks";
import Separator from "../Separator";

interface ReportI {
  id: string;
  name: string;
  grade: string;
  group: string;
}

const AttendanceReports = () => {
  const offlineGroupRef = useRef<HTMLSelectElement>(null);
  const weekRef = useRef<HTMLSelectElement>(null);
  const lessonRef = useRef<HTMLSelectElement>(null);
  const {
    offline_groups: offlineGroups,
    cats,
    data,
  } = useAppSelector((state) => state.store.portal);

  const [isPending, setIsPending] = useState<boolean>(false);
  const [isPending2, setIsPending2] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [error2, setError2] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");

  const [report, setReport] = useState<ReportI[] | null>(null);
  const [displayedReport, setDisplayedReport] = useState<ReportI[] | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [recordStart, setRecordStart] = useState(0);
  const [openLessonForStudentsList, setOpenLessonForStudentsList] = useState<
    string[]
  >([]);
  const [openLesson, setOpenLesson] = useState<boolean>(false);
  const [selectedGrade, setSelectedGrade] = useState<string>(null);
  const [selectedGroup, setSelectedGroup] = useState<string>(null);

  const addStudentToOpenLessonList = (uid: string) => {
    setOpenLessonForStudentsList([
      ...openLessonForStudentsList.filter((id) => id !== uid),
      uid,
    ]);
  };

  const removeStudentFromOpenLessonList = (uid: string) => {
    setOpenLessonForStudentsList([
      ...openLessonForStudentsList.filter((id) => id !== uid),
    ]);
  };

  const getYearWeeks = () => {
    let weeks: JSX.Element[] = [];
    for (let i = 1; i <= 52; i++) {
      weeks.push(<option value={i}>{i}</option>);
    }
    return weeks;
  };

  const getReport = async (e: any) => {
    e.preventDefault();
    setReport(null);
    setDisplayedReport(null);
    setCurrentPage(1);
    setIsPending(true);
    setReport(null);
    setError("");

    const week = weekRef.current.value;
    const group = offlineGroupRef.current.value;
    const token = getToken();

    try {
      const response = await fetch(
        endpoints.getAttendanceReport + `?week=${week}&group=${group}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const data = await response.json();
      const reportsArr = data.map((item: string) => {
        const record = item.split("||");
        return {
          id: record[0],
          name: record[1],
          grade: record[2],
          group: record[3],
        };
      });
      setReport(reportsArr);
      setDisplayedReport(
        reorderForPagination({
          arr: reportsArr,
          currentPage: 1,
          pageCount: 10,
        })
      );
      setCurrentPage(1);
      console.log(data);
    } catch (err) {
      setError("حدث خطأ");
    } finally {
      setIsPending(false);
    }
  };

  const handleOpenLesson = async () => {
    if (!lessonRef.current.value) {
      setError2("يجب إختيار الحصة");
      setTimeout(() => {
        setError2("");
      }, 5000);
      return;
    }
    setIsPending2(true);
    try {
      const response = await fetch(endpoints.openLessonForStudents, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify({
          lesson: lessonRef.current.value,
          students: openLessonForStudentsList,
        }),
      });
      if (!response.ok) throw new Error("حدث خطأ");
      setSuccessMsg("تم فتح الحصة للطلاب بنجاح");
      setTimeout(() => {
        setSuccessMsg("");
      }, 5000);
    } catch (err) {
      alert("حدث خطأ");
    } finally {
      setIsPending2(false);
    }
  };

  useEffect(() => {
    if (report)
      setDisplayedReport(
        reorderForPagination({
          arr: report,
          currentPage: currentPage,
          pageCount: 10,
        })
      );
  }, [currentPage]);

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <div className="table-responsive">
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            تقارير الحضور و الغياب
          </h3>
          <form onSubmit={(e) => getReport(e)}>
            <select
              ref={weekRef}
              className="form-select w-75 my-2"
              aria-label="Default select example"
            >
              <option value="">الإسبوع</option>
              {getYearWeeks()}
            </select>
            <select
              ref={offlineGroupRef}
              className="form-select w-75 my-2"
              aria-label="Default select example"
            >
              <option value="">المجموعة</option>
              {offlineGroups &&
                offlineGroups.map((group) => (
                  <option value={group}>{group}</option>
                ))}
            </select>
            {!isPending && (
              <input
                type="submit"
                className="btn ea-btn rounded-ea my-2"
                value="عرض"
              />
            )}
            {isPending && (
              <button
                className="btn ea-btn rounded-ea my-2"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                عرض
              </button>
            )}
          </form>
          {report && (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckCheckedAll"
                          onChange={(e) => {
                            if (e.target.checked) {
                              report.forEach((record) => {
                                addStudentToOpenLessonList(record.id);
                              });
                            } else {
                              report.forEach((record) => {
                                removeStudentFromOpenLessonList(record.id);
                              });
                            }
                          }}
                        />
                      </div>
                    </th>
                    <th scope="col">الطالب</th>
                    <th scope="col">الدرجة</th>
                    <th scope="col">المجموعة</th>
                    {/* <th scope="col">رقم الطالب</th> */}
                    {/* <th scope="col">إرسال التقرير لولي الأمر</th> */}
                    {/* <th scope="col">التاريخ</th> */}
                    {/* <th scope="col">تفاصيل</th> */}
                  </tr>
                </thead>
                <tbody>
                  {report &&
                    displayedReport.map(
                      (record, index) =>
                        index >= recordStart &&
                        index < recordStart + 10 && (
                          <tr key={record.id}>
                            <td scope="row">
                              {index + 1 + (currentPage - 1) * 10}
                            </td>
                            <td scope="row">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={`flexCheckChecked_${record.id}`}
                                  onChange={(e) => {
                                    e.target.checked
                                      ? addStudentToOpenLessonList(record.id)
                                      : removeStudentFromOpenLessonList(
                                          record.id
                                        );
                                  }}
                                  checked={
                                    openLessonForStudentsList.find(
                                      (uid) => uid === record.id
                                    )?.length > 0
                                  }
                                />
                              </div>
                            </td>
                            <td scope="row" style={{ whiteSpace: "nowrap" }}>
                              {record.name}
                            </td>
                            <td scope="row" style={{ whiteSpace: "nowrap" }}>
                              {record.grade}
                            </td>
                            <td scope="row" style={{ whiteSpace: "nowrap" }}>
                              {record.group}
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </div>
          )}
          <div className="d-flex">
            {report && currentPage * 10 < report.length && (
              <button
                onClick={() => setCurrentPage((prev) => prev + 1)}
                className="m-1 btn ea-btn rounded-ea"
              >
                التالي
              </button>
            )}
            {report && currentPage > 1 && (
              <button
                onClick={() => setCurrentPage((prev) => prev - 1)}
                className="m-1 btn ea-btn rounded-ea"
              >
                السابق
              </button>
            )}
          </div>
          {error && (
            <div
              id="ea-err-msg"
              className="alert alert-danger rounded-ea m-1 text-center w-75 align-self-center p-2"
              role="alert"
            >
              {error2}
            </div>
          )}
        </div>
        <Separator />
        <>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              onChange={(e) => setOpenLesson(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              فتح حصة معينة للطلاب الذين إخترتهم
            </label>
          </div>
          {openLesson && (
            <>
              <select
                className="form-select w-75 my-2"
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectedGrade(e.target.value);
                  setSelectedGroup("");
                  lessonRef.current.value = "";
                }}
                value={selectedGrade}
              >
                <option value="">المرحلة الدراسية</option>
                {cats &&
                  cats
                    .filter((item) => item.parent === 0)
                    .map((group) => (
                      <option value={group.term_taxonomy_id}>
                        {group.name.indexOf("|") !== 1
                          ? group.name.split("|")[0]
                          : group.name}
                      </option>
                    ))}
              </select>
              <select
                className="form-select w-75 my-2"
                aria-label="Default select example"
                onChange={(e) => setSelectedGroup(e.target.value)}
                value={selectedGroup}
              >
                <option value="">المجموعة</option>
                {cats &&
                  cats
                    .filter((item) => String(item.parent) === selectedGrade)
                    .map((grade) => (
                      <option value={grade.term_taxonomy_id}>
                        {grade.name.indexOf("|") !== 1
                          ? grade.name.split("|")[0]
                          : grade.name}
                      </option>
                    ))}
              </select>
              <select
                ref={lessonRef}
                className="form-select w-75 my-2"
                aria-label="Default select example"
              >
                <option value="">الحصة</option>
                {data &&
                  data
                    .filter(
                      (item) => String(item.term_taxonomy_id) === selectedGroup
                    )
                    .map((lesson) => (
                      <option value={lesson.ID}>{lesson.post_title}</option>
                    ))}
              </select>
              {!isPending2 && (
                <input
                  type="button"
                  onClick={handleOpenLesson}
                  className="btn ea-btn rounded-ea my-2"
                  value="فتح الحصة"
                />
              )}
              {isPending2 && (
                <button
                  className="btn ea-btn rounded-ea my-2"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  فتح الحصة
                </button>
              )}
              {error2 && (
                <div
                  id="ea-err-msg"
                  className="alert alert-danger rounded-ea m-1 text-center w-75 align-self-center p-2"
                  role="alert"
                >
                  {error2}
                </div>
              )}
              {successMsg && (
                <div
                  id="ea-err-msg"
                  className="alert alert-success rounded-ea m-1 text-center w-75 align-self-center p-2"
                  role="alert"
                >
                  {successMsg}
                </div>
              )}
            </>
          )}
        </>
      </div>
    </Animation>
  );
};

export default AttendanceReports;
