// import './index.css';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { setTeacherColorScheme } from "../scripts/setTeacherColorScheme";
import { useAppSelector } from "../store/hooks";
import Animation from "./Animation";
import FileUpload from "./FileUpload";
import MultipleDeviceNotice from "./MultipleDeviceNotice";
import NotFound from "./NotFound";
import AccountPage from "./account/AccountPage";
import AiAssistant2 from "./ai-assistant/AiAssistant2";
import CompleteInfo from "./complete-info/CompleteInfo";
import Dashboard from "./dashboard/Dashboard";
import Groups from "./home/Groups";
import Home from "./home/Home";
import PasswordReset from "./home/PasswordReset";
import SchoolGrades from "./home/SchoolGrades";
import LessonDetails from "./lesson/lessonRefactor/Lesson";
import Footer from "./nav-footer/Footer";
import Navbar from "./nav-footer/Navbar";
import Invoice from "./orders/Invoice";
import Invoices from "./orders/Invoices";
import Grade from "./quiz/Grade";
import Grades from "./quiz/Grades";
import LessonsList from "./teacher/LessonsList";

const firebaseConfig = {
  apiKey: "AIzaSyDRKG40ff8S3qfjVHit3KB0gvYC2WRPmPE",
  authDomain: "eliteacademyeg-a7552.firebaseapp.com",
  projectId: "eliteacademyeg-a7552",
  storageBucket: "eliteacademyeg-a7552.appspot.com",
  messagingSenderId: "958297622504",
  appId: "1:958297622504:web:7bdd5201c7c93f6422465d",
  measurementId: "G-BNV4PJQZJ1",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

function App() {
  const portal = useAppSelector((state) => state.store.portal);
  const user = useAppSelector((state) => state.store.user);

  useEffect(() => {
    console.log("Current location: ", window.location);
  }, [window.location.pathname]);

  useEffect(() => {
    console.log("Current Portal: ", portal);
  }, [portal]);

  useEffect(() => {
    console.log("Current User: ", user);
  }, [user]);

  const { user: userData } = useAppSelector((state) => state.store);

  // return (
  //   <div className="closed-for-updates container mx-5 my-2">
  //     <div className="d-flex">
  //       <img src={UPDATING} alt="updating" className="mx-auto" />
  //     </div>
  //     <div className="d-flex">
  //       <p className="h1 mx-auto">الموقع مغلق للتحديثات</p>
  //     </div>
  //     <div className="d-flex">
  //       <p className="h2 mx-auto">أعد المحاولة بعد ساعة</p>
  //     </div>
  //   </div>
  // );

  useEffect(() => {
    setTeacherColorScheme(window.location.hostname);
  });

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Animation>
          <div className="content">
            <Routes>
              {["teacher", "admin"].includes(userData?.role) && (
                <Route path="/dashboard" element={<Dashboard />} />
              )}
              <Route path="/account" element={<AccountPage />} />
              <Route path="/grades/:id" element={<Grade />} />
              <Route path="/grades" element={<Grades />} />
              <Route path="/invoices/:id" element={<Invoice />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/" exact element={<Home />} />
              <Route path="/school-grades" exact element={<SchoolGrades />} />
              <Route path="/:schoolgrade/groups" exact element={<Groups />} />
              <Route
                path="/:schoolgrade/:group/lessons"
                exact
                element={<LessonsList />}
              />
              <Route
                path="/:schoolgrade/:group/:lesson"
                element={<LessonDetails />}
              />
              <Route path="/drive" element={<FileUpload />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/ai-assistant" element={<AiAssistant2 />} />
              <Route path="/complete-info" element={<CompleteInfo />} />
              {/* <Route path="/live-stream" element={<LiveLesson />} />
              <Route
                path="/student-live-stream"
                element={<StudentLiveLesson />}
              /> */}
              {/* Catch-all route for unmatched paths */}
              <Route
                path="/multiple-device-notice"
                element={<MultipleDeviceNotice />}
              />
              {/* <Route
                path="/pwa"
                element={
                  <div className="m-5">
                    <button
                      onClick={async () => {
                        if (deferredPrompt) {
                          // Show the install prompt
                          deferredPrompt.prompt();
                          // Wait for the user to respond to the prompt
                          deferredPrompt.userChoice.then((choiceResult) => {
                            if (choiceResult.outcome === "accepted") {
                              console.log("User accepted the install prompt");
                            } else {
                              console.log("User dismissed the install prompt");
                            }
                            // Clear the deferredPrompt variable since it can only be used once
                            // deferredPrompt = null;
                          });
                        }
                      }}
                      className="btn ea-btn rounded-ea"
                    >
                      PWA
                    </button>
                  </div>
                }
              /> */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Animation>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
