import { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { marked } from "marked";
import { endpoints } from "../../hooks/endpoints";

const AiAssistant2 = () => {
  const questionRef = useRef();
  const [isPending, setIsPending] = useState(false);
  const [dialogue, setDialogue] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const domain = window.location.hostname;

  const handleAsk = () => {
    if (questionRef.current.value.length === 0) return;
    let lastQuestion = questionRef.current.value;
    if (lastQuestion[0] === " ") {
      lastQuestion = lastQuestion.slice(1);
    }
    questionRef.current.value = "";
    setDialogue((prev) => [...prev, { author: "user", content: lastQuestion }]);
    setIsPending(true);
    setError(false);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      localStorage.getItem(`${window.location.hostname}_user`)
        ? "Bearer" +
            JSON.parse(localStorage.getItem(`${window.location.hostname}_user`))
              .token
        : null
    );

    var raw = [...dialogue, { author: "user", content: lastQuestion }];

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ messages: raw }),
      redirect: "follow",
    };

    fetch(endpoints.vertexAiAssistant, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("ai response", data);
        if (!data.predictions[0].candidates[0].content) {
          setIsPending(false);
          setError(true);
          setDialogue((prev) => {
            prev.filter((msg) => msg.content !== lastQuestion);
          });
        } else {
          setIsPending(false);
          setDialogue((prev) => [
            ...prev,
            {
              author: "bot",
              content: data.predictions[0].candidates[0].content,
              citationMetadata: { citations: [] },
            },
          ]);
        }
      })
      .catch((err) => {
        console.log("ai response", err);
        setIsPending(false);
        setError(true);
        setDialogue((prev) => {
          prev.pop();
          return [...prev];
        });
      });
  };

  useEffect(() => {
    if (!(domain === "localhost" || domain.includes("galal"))) {
      navigate("/");
    }

    /*EliteTransition()*/
  }, []);

  return (
    <div className="container responsive-pages py-3">
      <h2
        style={{ textAlign: "center" }}
        className="p-3 my-2 bg-ea-primary rounded-ea text-white"
      >
        AI Assistant
      </h2>
      <div className="alert alert-primary rounded-ea border-2" role="alert">
        يجب ادخال الأسئلة في صيغة سؤال و ليس في صيغة أمر و يجب وضع علامة استفهام
        في اخر كل سؤال
      </div>
      <div className="bg-white p-2 rounded-ea shadow">
        <div>
          {dialogue !== null &&
            dialogue.map((query) => (
              <div
                dir="ltr"
                className={`mt-3 p-3 ${
                  query.author === "user" ? "bg-ddd" : "bg-ea-base"
                } rounded-ea w-75`}
                style={{ float: query.author === "user" ? "right" : "left" }}
              >
                {/* {query.text
                  .split("*")
                  .map(
                    (text) =>
                      text !== " " && (
                        <p>{query.role === "user" ? text : text}</p>
                      )
                  )} */}
                {query.content}
              </div>
            ))}
        </div>
        <textarea
          ref={questionRef}
          dir="ltr"
          lang="en"
          className="w-100 my-3 p-3 rounded-ea border-0 bg-eee"
          placeholder="Write your question here..."
          rows={2}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleAsk();
            }
          }}
        ></textarea>
        <div className="d-flex">
          {!isPending && (
            <button
              onClick={handleAsk}
              className="btn ea-btn mx-auto"
              type="button"
            >
              <i className="bi bi-send"></i>
            </button>
          )}
          {isPending && (
            <button className="btn ea-btn mx-auto" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </button>
          )}
        </div>
        {error && (
          <div
            dir="ltr"
            className="mt-3 p-3 rounded-ea alert alert-danger"
            style={{ textAlign: "center" }}
            role="alert"
          >
            Something went wrong!
          </div>
        )}
      </div>
    </div>
  );
};

export default AiAssistant2;
