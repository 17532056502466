import React, { useEffect, useState } from 'react';
import Spinner from './Spinner';


const FileUpload = () => {

  // const [imgSrc, setImgSrc] = useState('test')

  // const [file, setFile] = useState(null);

  // const handleFileChange = (event) => {
  //   setFile(event.target.files[0]);
  // };

  // const handleUpload = async () => {
  //   if (!file) {
  //     alert('Please select a file.');
  //     return;
  //   }

  //   try {
  //     const formData = new FormData();
  //     formData.append('file', file, file.name);

  //     const response = await fetch( endpoints.drive , {
  //       method: 'POST',
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       if (data.success) {
  //         // File uploaded successfully
  //         // alert('File uploaded. File ID: ' + data.fileId);
  //         setImgSrc(`https://drive.google.com/uc?id=${data.fileId}`)
  //       } else {
  //         // File upload failed
  //         alert('File upload failed. ' + data.message);
  //       }
  //     } else {
  //       // Server error
  //       alert('An error occurred while uploading the file.');
  //     }
  //   } catch (error) {
  //     // Network error
  //       alert('An error occurred while uploading the file.');
  //   }
  // };
useEffect(() => {
  /*EliteTransition()*/
},[])
  const urls = Array(1000).fill(`https://eliteacademyeg.com/wp-json/elite/v1/lesson?timestamp=${Date()}&lesson=chapter-1-lesson-5-2024`);

urls.forEach((url, i) => {
    fetch(url, {
      method:'GET'
    })
        .then(response => response.json())
        .then(data => console.log(`Response ${i+1}:`, data))
        .catch(error => console.error(`Error in request ${i+1}:`, error));
});


  return (
    <Spinner />
  );
};

export default FileUpload;
