import { useState } from "react";
import { fetchQuestions } from "../utils";
import { useAppSelector } from "../../../../store/hooks";

const FetchQuestions = ({
  isLoading,
  teacherBankCats,
  setQuestionsData,
  setIsLoading,
}) => {
  const [chosenCat, setChosenCat] = useState(null);
  const { userProfileData } = useAppSelector((state) => state.store);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        fetchQuestions({
          cat: chosenCat,
          setQuestionsData,
          setIsLoading,
        });
      }}
      className="p-3"
    >
      <h4
        style={{ textAlign: "center" }}
        className="mt-3 mb-4 bg-ea-primary py-2 px-3 text-white rounded-ea shadow fw-bold"
      >
        عرض الأسئلة
      </h4>
      <select
        className="form-select mb-3 rounded-ea"
        value={chosenCat}
        onChange={(e) => setChosenCat(e.target.value)}
        required
      >
        <option>إختر القسم</option>
        {teacherBankCats.map((ans) => (
          <option value={ans}>
            {ans.indexOf(` - ${userProfileData.teacher_prefix}`) > -1
              ? ans.replace(` - ${userProfileData.teacher_prefix}`, "")
              : ans}
          </option>
        ))}
      </select>
      {!isLoading && (
        <input
          type="submit"
          disabled={isLoading}
          id="publish_new_quiz_submit"
          className="ea-btn btn w-100 rounded-ea"
          value="بحث"
        />
      )}
      {isLoading && (
        <button className="ea-btn btn w-100 rounded-ea" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          جاري البحث...
        </button>
      )}
    </form>
  );
};

export default FetchQuestions;
