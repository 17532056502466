import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setCurrentHwProgress,
  setCurrentQuizProgress,
} from "../../../store/slices/storeSlice";
import QuestionImg from "../QuestionImg";
import { AssessmentMode, QuestionData } from "../typesAndEnums";

const QuestionMCQ = ({
  assessmentMode,
  assessmentId,
  question,
  isEnded,
  mustAnswerAll,
  hideCorrectAnswers,
}: {
  assessmentMode: string;
  assessmentId: number;
  question: QuestionData;
  isEnded: boolean;
  mustAnswerAll: boolean;
  hideCorrectAnswers: boolean;
}) => {
  const dispatch = useAppDispatch();
  const getReversedAnswers = (answers: string[]) => {
    const reversedAnswers = [...answers].reverse(); // Reverse the answers
    return reversedAnswers;
  };
  const { currentQuizProgress, currentHwProgress } = useAppSelector(
    (state) => state.store
  );

  const currentAssessmentProgress =
    assessmentMode === AssessmentMode.QUIZ
      ? currentQuizProgress
      : currentHwProgress;

  const handleOnChange = async (e: any, question: QuestionData) => {
    const progress = await JSON.parse(
      localStorage.getItem(`${assessmentMode}_${assessmentId}_user_answers`) ??
        "{}"
    );
    progress[question.question_id] = e.target.value;
    localStorage.setItem(
      `${assessmentMode}_${assessmentId}_user_answers`,
      JSON.stringify(progress)
    );
    if (assessmentMode === AssessmentMode.QUIZ) {
      dispatch(
        setCurrentQuizProgress({
          [question.question_id]: e.target.value,
        })
      );
    } else {
      dispatch(
        setCurrentHwProgress({
          [question.question_id]: e.target.value,
        })
      );
    }
  };

  const isChecked = (question: QuestionData, answer: string) => {
    if (isEnded && question?.userAnswer === answer) return true;
    if (
      !isEnded &&
      currentAssessmentProgress?.[question?.question_id] === answer
    )
      return true;
    return false;
  };

  return (
    <div
      key={question.question_id}
      className={`p-2 bg-light mb-3 mx-4 border-ea-2 rounded-ea ${
        isEnded ? "quiz-ended" : ""
      }`}
    >
      <div className="rounded-ea d-flex">
        {question.img && (
          <QuestionImg src={question.img} alt={question.title} />
        )}
      </div>
      {question.content ? (
        <>
          {question.content.indexOf("\n") !== 1 ? (
            question.content.split("\n").map((p: string) => <p>{p}</p>)
          ) : (
            <p key={question.question_id}>{question.content}</p>
          )}
        </>
      ) : (
        ""
      )}
      <div
        className="my-2 btn-group w-100"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        {getReversedAnswers(question.answers).map((answer) => (
          <>
            <input
              key={`${question.question_id}-${answer}`}
              type="radio"
              className="btn-check"
              checked={isChecked(question, answer)}
              name={String(question.question_id)}
              id={`btnradio-${question.question_id}-${answer}`}
              value={answer}
              autoComplete="off"
              required={mustAnswerAll}
              onChange={(e) => handleOnChange(e, question)}
            />
            <label
              key={`label-${question.question_id}-${answer}`}
              className="btn btn-outline-primary fw-bold"
              htmlFor={`btnradio-${question.question_id}-${answer}`}
            >
              {answer}
            </label>
          </>
        ))}
      </div>
      {isEnded && question?.isCorrect && (
        <div className="d-flex">
          <div
            style={{ maxHeight: "50px" }}
            className="d-flex py-2 px-4 rounded-ea alert alert-success mx-auto"
            role="alert"
          >
            <p className="fw-bold m-auto">إجابة صحيحة</p>
          </div>
        </div>
      )}
      {isEnded && !question?.isCorrect && (
        <div className="d-flex">
          <div
            style={{ maxHeight: "50px" }}
            className="d-felx py-2 px-4 rounded-ea alert alert-danger mx-auto"
            role="alert"
          >
            <p className="fw-bold m-auto">
              {hideCorrectAnswers
                ? "إجابة خاطئة"
                : `الإجابة الصحيحة هي ${question.correct_answer}`}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionMCQ;
