import YouTube from "react-youtube";
import useEliteVideoPlayer from "../../../hooks/useEliteVideoPlayer";
import "../../../styles/eliteVideoPlayer.css";
import { LessonDataI } from "../../quiz/typesAndEnums";
import Fullscreen from "./components/Fullscreen";
import InitialPlayButton from "./components/InitialPlayButton";
import MobileControls from "./components/MobileControls";
import Playback from "./components/Playback";
import ProgressBar from "./components/Playbar";
import PlayButton from "./components/PlayButton";
import SeekByStep from "./components/SeekByStep";
import Timer from "./components/Timer";
import Volume from "./components/Volume";

function EliteVideoPlayer({
  vidId,
  expirtDate,
  lessonData,
}: {
  vidId: string;
  expirtDate: number;
  lessonData: LessonDataI;
}) {
  const {
    isVisible,
    playerId,
    playerReady,
    fullScreen,
    playVideo,
    volume,
    muted,
    currentTime,
    totalTime,
    initialPlay,
    fallbackFullScreen,
    setInitialPlay,
    skipTime,
    togglePlayPause,
    toggleMuteState,
    updateVolume,
    updatePlaybackRate,
    updatePlaybackQuality,
    updateTimeline,
    toggleFullScreen,
    getReference,
  } = useEliteVideoPlayer({ vidId, expirtDate, lessonData });

  return (
    <div className={fallbackFullScreen ? "ea-video-fullscreen" : ""}>
      <div
        onClick={initialPlay ? togglePlayPause : () => {}}
        id={`video-${playerId}`}
        className={`ea-video-container w-100 ${playVideo ? "" : "paused"}`}
        dir="ltr"
        style={{ display: playerReady ? "flex" : "none" }}
      >
        <InitialPlayButton
          togglePlayPause={togglePlayPause}
          initialPlay={initialPlay}
          setInitialPlay={setInitialPlay}
        />
        <div
          id={`ea-video-controls-container-${playerId}`}
          onClick={(e) => e.stopPropagation()}
          className={`ea-video-controls-container ${
            initialPlay ? "" : "invisible"
          }`}
        >
          <ProgressBar
            playerId={playerId}
            totalTime={totalTime}
            currentTime={currentTime}
            updateTimeline={updateTimeline}
          />
          <div className="ea-controls pt-0">
            <PlayButton
              playVideo={playVideo}
              togglePlayPause={togglePlayPause}
            />
            <SeekByStep skipTime={skipTime} />
            <Volume
              volume={volume}
              muted={muted}
              toggleMuteState={toggleMuteState}
              updateVolume={updateVolume}
            />
            <Timer totalTime={totalTime} currentTime={currentTime} />
            <Playback
              isVisible={isVisible}
              updatePlaybackRate={updatePlaybackRate}
              updatePlaybackQuality={updatePlaybackQuality}
            />
            <MobileControls
              updatePlaybackQuality={updatePlaybackQuality}
              updatePlaybackRate={updatePlaybackRate}
              updateVolume={updateVolume}
              muted={muted}
              volume={volume}
            />
            <Fullscreen
              fullScreen={fullScreen}
              toggleFullScreen={toggleFullScreen}
            />
          </div>
        </div>
        <YouTube
          className="w-100"
          videoId={vidId}
          onReady={getReference}
          opts={{
            playerVars: {
              autoplay: 1,
              controls: 0,
              enablejsapi: 1,
              rel: 0,
              disablekb: 1,
              iv_load_policy: 3,
              loop: 1,
              start: 0,
            },
          }}
        />
      </div>
    </div>
  );
}

export default EliteVideoPlayer;
