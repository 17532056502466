const LoadingButton = ({
  styles,
  handleOnClick,
  text,
  loading,
  additionalClasses,
}) => {
  return !loading ? (
    <button
      style={styles ? styles : null}
      type="button"
      className={`btn ea-btn rounded-ea ${additionalClasses}`}
      onClick={() => {
        handleOnClick();
      }}
    >
      {text}
    </button>
  ) : (
    <button
      style={styles ? styles : null}
      className={`btn ea-btn rounded-ea ${additionalClasses}`}
      type="button"
      disabled
    >
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>{" "}
      {text}...
    </button>
  );
};

export default LoadingButton;
