import { useRef, useState } from "react";
import { addNewBankCategory } from "../utils";
import { invokeUserRefetch } from "../../../../store/slices/storeSlice";
import { useAppDispatch } from "../../../../store/hooks";


const AddNewBankCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [resMsg, setResMsg] = useState(false);
  const newCatRef = useRef(null);
  const dispatch = useAppDispatch();

  return (
    <div className="p-3">
      <h4
        style={{ textAlign: "center" }}
        className="mt-3 mb-4 bg-ea-primary py-2 px-3 text-white rounded-ea shadow fw-bold"
      >
        إضافة قسم جديد لبنك الأسئلة
      </h4>
      <div className="form-floating mb-3">
        <input
          ref={newCatRef}
          id="new_bank_cat_input"
          type="text"
          className="form-control rounded-ea"
          required
        />
        <label htmlFor="new_bank_cat_input">أكتب إسم القسم</label>
      </div>
      {!isLoading && (
        <button
          onClick={async () => {
            await addNewBankCategory({
              newCat: newCatRef.current.value,
              setIsLoading,
              setResMsg,
            });
            dispatch(invokeUserRefetch());
          }}
          className="btn ea-btn rounded-ea"
        >
          إضافة القسم
        </button>
      )}
      {isLoading && (
        <button className="ea-btn btn rounded-ea" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          جاري الإضافة...
        </button>
      )}
      <br />
      {resMsg && (
        <div
          style={{ display: "inline-block" }}
          className={`alert alert-${
            resMsg.status ? "success" : "danger"
          } h-50 mt-2 rounded-ea`}
          role="alert"
        >
          {resMsg.msg}
        </div>
      )}
    </div>
  );
};

export default AddNewBankCategory;
