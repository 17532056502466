import { QuestionData } from "../components/quiz/typesAndEnums";

export type ResultI = { score: number; maxScore: number } & {
  [key: string]: {
    correct: boolean;
    answer: string;
  };
};

const useAssessmentController = () => {
  const getResult = async ({
    userAnswers,
    questions,
  }: {
    userAnswers: {
      [key: number]: string;
    };
    questions: QuestionData[];
  }) => {
    let score = 0;
    let maxScore = 0;
    let gradedQuestions: {
      [key: string]: {
        correct: boolean;
        answer: string;
      };
    } = {};
    questions.forEach((q) => {
      maxScore += q.weight;
      const isCorrect = q.correct_answer === userAnswers[q.question_id];
      if (isCorrect) score += q.weight;
      gradedQuestions[q.question_id] = {
        answer: userAnswers[q.question_id],
        correct: isCorrect,
      };
    });

    return {
      score,
      maxScore,
      ...gradedQuestions,
    } as ResultI;
  };

  return { getResult };
};

export default useAssessmentController;
