import { useRef, useState } from "react";
import { endpoints } from "../../../hooks/endpoints";
import { invokeUserRefetch } from "../../../store/slices/storeSlice";
import { getToken } from "../../../hooks/utils";
import { useAppDispatch } from "../../../store/hooks";

const CodePurchase = ({ lessonData, isMobile }) => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useAppDispatch();

  const codeRef = useRef(null);

  const handlePurchase = (e) => {
    e.preventDefault();
    setIsPending(true);
    const code = codeRef.current.value;
    fetch(
      endpoints.codePurchase +
        "?code=" +
        code +
        "&lesson=" +
        lessonData.lesson_id +
        "&ismobile=" +
        isMobile,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        // signal: abortCont.signal
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("this is result: ", result);
        if (
          result !== "هذا الكود مستخدم من قبل , تواصل مع المدرس" &&
          result !== "الكود غير صحيح" &&
          result !== "هذا الكود غير مخصص لهذه الحصة"
        ) {
          dispatch(invokeUserRefetch());
        } else {
          setError(result);
        }
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err);
        setError("حدث خطأ");
        setIsPending(false);
      });
  };

  return (
    <div>
      <form className="w-100 d-flex m-auto" onSubmit={handlePurchase}>
        <div className="mx-auto d-flex responsive-purchase-code">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="rounded-ea form-control"
              id="floatingInput"
              placeholder="XXXX-XXXX-XXXX"
              required
              ref={codeRef}
            />
            <label htmlFor="floatingInput">أدخل الكود</label>
          </div>
          {!isPending && (
            <input
              type="submit"
              value="شراء"
              className="px-3 btn rounded-ea ea-btn my-auto mx-3"
            />
          )}
          {isPending && (
            <button
              className="px-3 btn ea-btn my-auto mx-3 d-flex rounded-ea"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm mx-2"
                role="status"
                aria-hidden="true"
              ></span>
              شراء...
            </button>
          )}
        </div>
      </form>
      {error && (
        <div className="d-flex">
          <div
            className="mx-auto mx-2 alert alert-danger px-3 my-2 rounded-ea"
            role="alert"
          >
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

export default CodePurchase;
