import React, { useState } from "react";
import { handleDeleteQuestion, trimQuestion } from "../utils";
import DeleteButton from "./DeleteButton";
import { getFileUrl } from "../../../../hooks/utils";

function Qimg({
  question,
  src,
  alt,
  maxHeight = 1000,
  border = "1px solid #ddd",
  paragraph,
  weight,
  deleteButton = false,
  setQuestionsData,
  questionsData,
}) {
  const [imageKey, setImageKey] = useState(0); // Add a key state

  const handleImageError = () => {
    // Image failed to load, so let's attempt to reload it
    setImageKey(imageKey + 1); // Change the key to force a re-render
  };

  const [loading, setLoading] = useState(false);

  return (
    <div
      className="card p-2"
      style={{
        border: "1px solid #aaa",
        borderRadius: "15px",
        display: "block",
      }}
    >
      <img
        key={imageKey} // Use the key to force a re-render when needed
        width="100%"
        className="card-img-top"
        style={{ maxHeight: maxHeight, border: border }}
        src={decodeURIComponent(src)}
        alt={alt}
        onError={handleImageError}
      />
      <div className="card-body">
        <p className="card-text">
          رأس السؤال : {trimQuestion({ paragraph, limit: 200 })}
        </p>
        <p className="card-text">الدرجة : {weight}</p>
        {deleteButton && (
          <DeleteButton
            styles={{ position: "absolute", left: "3%", bottom: "5%" }}
            handleOnClick={() =>
              handleDeleteQuestion({
                qId: question.id,
                setLoading,
                setQuestionsData,
                questionsData,
              })
            }
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </div>
    </div>
  );
}

export default Qimg;
