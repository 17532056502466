import React from "react";
import FetchQuestionsInQuiz from "./FetchQuestionsInQuiz";

const ChooseQuestionModal = ({ addQuestion, questionNum }) => {
  return (
    <>
      <div
        className="accordion border-0 my-2 bg-transparent bg-ea-base rounded-ea"
        id={`accordionExample-${questionNum}`}
      >
        <div className="accordion-item border-0 bg-transparent">
          <h2
            className="accordion-header border-0 bg-transparent"
            id={`headingOne-${questionNum}`}
          >
            <button
              className="accordion-button border-0 bg-transparent"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseOne-${questionNum}`}
              aria-expanded="false"
              aria-controls={`collapseOne-${questionNum}`}
              id={`acc-btn-${questionNum}`}
            >
              <button type="button" className="btn ea-btn rounded-ea">
                أضف هذا السؤال من بنك الأسئلة
              </button>
            </button>
          </h2>
          <div
            id={`collapseOne-${questionNum}`}
            className="accordion-collapse collapse show border-0 bg-transparent"
            aria-labelledby={`#headingOne-${questionNum}`}
            data-bs-parent={`#accordionExample-${questionNum}`}
          >
            <div className="accordion-body">
              <h5
                className="modal-title"
                id={`staticBackdropLabel${questionNum}`}
              >
                إختر السؤال
              </h5>
              <FetchQuestionsInQuiz
                addQuestion={addQuestion}
                questionNum={questionNum}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseQuestionModal;
