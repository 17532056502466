import { NavLink as Link, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";

const Sidebar = () => {
  const { portal } = useAppSelector((state) => state.store);
  const { lesson: lessonSlug, group } = useParams();
  const lessons = portal?.data?.filter((item) => item.slug === group);

  return (
    <div className="bg-white rounded-ea shadow">
      <div className="p-2">
        <div className="accordion" id="accordionPanelsStayOpenExample">
          <div className="accordion-item border-0">
            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
              <button
                className="accordion-button bg-ea-base-2 text-ea-primary rounded-ea border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                كل الحصص
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="p-0 accordion-body">
                {lessons &&
                  // lessonData &&
                  lessons.map((sideLesson) => (
                    <Link
                      // onClick={() =>
                      //   sideLesson.slug === lessonSlug ? false : true
                      // }
                      key={sideLesson.ID}
                      to={`/category/${group}/${sideLesson.post_name}`}
                      className={`row grow text-${
                        // sideLesson.purchased ||
                        +sideLesson._regular_price === 0 ||
                        // lessonData.is_author ||
                        // lessonData.newly_purchased &&
                        lessonSlug === sideLesson.slug
                          ? "ea-primary"
                          : "secondary"
                      } text-decoration-none mx-1 my-2 p-2 bg-${
                        // sideLesson.purchased ||
                        +sideLesson._regular_price === 0 ||
                        // lessonData.is_author ||
                        // lessonData.newly_purchased &&
                        lessonSlug === sideLesson.slug
                          ? "ea-base-2"
                          : "eee"
                      } rounded-ea`}
                    >
                      <div className="col-10">{sideLesson.post_title}</div>
                      <div className="col-2">
                        {
                          // lessonData.newly_purchased &&
                          lessonSlug === sideLesson.slug
                            ? ""
                            : //!sideLesson.purchased &&
                              // !lessonData.is_author &&
                              +sideLesson._regular_price !== 0 && (
                                <i className="bi bi-lock-fill"></i>
                              )
                        }
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
