import { getFileUrl } from "../hooks/utils";

const HomeCarousel = () => {
  const domain = window.location.hostname;

  return (
    <div className="typewriter d-flex">
      {(domain.includes("localhost") || domain.includes("mohamedyehya")) && (
        <img
          className="m-2 responsive-typewriter-img"
          height={32}
          width={42}
          src="https://api.eliteacademyeg.com/wp-content/uploads/2024/01/france-flag.png"
          alt=""
        />
      )}
      <h1
        className="responsive-typewriter"
        style={{
          color: "black",
          // display:'flex',
          // flexWrap:'wrap',
        }}
      >
        {domain.includes("mohamedyehya") && "Bienvenue sur notre site officiel"}
        {domain.includes("mohamedgalal") &&
          "Your success is our responsibility"}
        {domain.includes("localhost") && "Your success is our responsibility"}
      </h1>
    </div>
  );
};

export default HomeCarousel;
