import { Link } from "react-router-dom";

const NotLoggedIn = () => {
  return (
    <div className="container text-center p-5 m-auto">
      <div className="alert alert-danger fw-bold rounded-ea w-100" role="alert">
        يجب تسجيل الدخول أولاً , إضغط{" "}
        <Link to="/" className="px-2 py-0 mx-1 fw-bold btn ea-btn">
          هنا
        </Link>{" "}
        للذهاب للصفحة الرئيسية
      </div>
    </div>
  );
};

export default NotLoggedIn;
