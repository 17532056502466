import { endpoints } from "../../../../hooks/endpoints";
import { getToken } from "../../../../hooks/utils";

export const decrementVideoView = ({
  onlyOneVideo,
  unlimitedExpiryDate,
  lesson_id,
}: {
  onlyOneVideo: boolean;
  unlimitedExpiryDate: boolean;
  lesson_id: number;
}) => {
  if (onlyOneVideo && !unlimitedExpiryDate) {
    fetch(endpoints.videoView, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify({
        lesson_id,
      }),
      redirect: "follow",
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
