const StudentBlocked = () => {
  return (
    <div className="container text-center p-5 m-auto">
      <div className="alert alert-danger fw-bold rounded-ea w-100" role="alert">
        قام المدرس بمنعك من دخول الحصص , تواصل مع المدرس
      </div>
    </div>
  );
};

export default StudentBlocked;
