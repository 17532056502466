const DeleteButton = ({ styles, handleOnClick, loading }) => {
  return !loading ? (
    <span style={styles} type="button" onClick={handleOnClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="text-danger bi bi-x-circle-fill"
        viewBox="0 0 16 16"
      >
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
      </svg>
    </span>
  ) : (
    <span style={styles} type="button" disabled>
      <span
        style={{ width: "1.5rem", height: "1.5rem" }}
        className="text-danger spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>{" "}
    </span>
  );
};

export default DeleteButton;
