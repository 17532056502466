import Animation from "../../../Animation";
import Purchase from "../../../orders/Purchase";
import { LessonDataI } from "../../../quiz/typesAndEnums";
import { LessonTabs } from "../hooks/useGetLesson";
import LessonContent from "./LessonContent";
import LessonNavigationTabs from "./LessonNavigationTabs";

const LessonPageContentMapper = ({
  lessonData,
  notFinishedPrereq,
  pastLastPurchaseDate,
  isMobile,
  currentContent,
  setCurrentContent,
}: {
  lessonData: LessonDataI;
  notFinishedPrereq: boolean;
  pastLastPurchaseDate: boolean;
  isMobile: boolean;
  currentContent: LessonTabs;
  setCurrentContent: (x: LessonTabs) => void;
}) => {
  if (lessonData.is_purchased || lessonData.is_author)
    return (
      <>
        <LessonNavigationTabs
          lessonData={lessonData}
          setCurrentContent={setCurrentContent}
          currentContent={currentContent}
        />

        <Animation>
          <LessonContent
            lessonData={lessonData}
            currentContent={currentContent}
          />
        </Animation>
      </>
    );

  if (notFinishedPrereq)
    return (
      <Animation>
        <div className="alert alert-danger m-5 rounded-ea" role="alert">
          يجب إنهاء واجب الحصة السابقة , إذا كانت هذه الحصة الأولى لك مع المدرس
          برجاء التواصل مع المدرس
        </div>
      </Animation>
    );

  if (pastLastPurchaseDate)
    return (
      <Animation>
        <div className="alert alert-danger m-5 rounded-ea" role="alert">
          إنتهى الوقت المسموح لشراء الحصة , تواصل مع المدرس
        </div>
      </Animation>
    );

  return <Purchase lessonData={lessonData} isMobile={isMobile} />;
};

export default LessonPageContentMapper;
