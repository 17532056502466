import { ErrorTags, reportAppError } from "../../../errorReporter";
import { endpoints } from "../../../hooks/endpoints";
import { ResultI } from "../../../hooks/useAssessmentController";
import { getToken } from "../../../hooks/utils";
import { store } from "../../../store/store";
import { LessonDataI } from "../typesAndEnums";

export const handleSubmitQuizToServer = async ({
  lessonData,
  result,
  setSubmitError,
  setIsSubmitPending,
}: {
  lessonData: LessonDataI;
  result: ResultI;
  setSubmitError: (x: boolean) => void;
  setIsSubmitPending: (x: boolean) => void;
}) => {
  try {
    setSubmitError(false);
    setIsSubmitPending(true);
    const reduxStore = store.getState().store;
    const user = reduxStore.userProfileData;
    const currentTeacher = reduxStore.currentTeacher;
    const questionsIds = Object.keys(result).filter((key) =>
      !["score", "maxScore"].includes(key)
    );
    const answers = questionsIds
      .map((qID) => `${qID}-${result[qID].answer}-${result[qID].correct}`)
      .join(",");

    await fetch(endpoints.submitQuiz, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify({
        title: `${lessonData.title}-${lessonData.quiz_id}-${lessonData.quiz_title}-${user.display_name}`,
        quiz_id: lessonData.quiz_id,
        quiz: `${lessonData.quiz_title}-${lessonData.quiz_id}`,
        student: `${user.display_name}-${user.user_email}`,
        grade: result.score,
        raw_data: JSON.stringify(result),
        answers,
        teacher: currentTeacher,
        lesson_id: lessonData.lesson_id,
      }),
    });
  } catch (error) {
    reportAppError({
      e: error,
      info: "error while submitting a quiz",
      errorTag: ErrorTags.LessonScreen,
    });
    setSubmitError(true);
  } finally {
    setIsSubmitPending(false);
  }
};
