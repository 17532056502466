import { NavLink as Link } from "react-router-dom";

const NotFound = () => {

    return (
        <div className="not-found container">
            <div className="px-3 d-flex">
                <img
                    className="m-auto"
                    style={{maxWidth:'45%'}}
                    src="https://firebasestorage.googleapis.com/v0/b/eliteacademyeg-a7552.appspot.com/o/not-found-1.svg?alt=media"
                    alt=""
                />
            </div>
            <div className="d-flex">
                <Link to="/" className="m-auto text-ea">
                    <p className="fw-bold fs-3 text-ea">الرجوع للصفحة الرئيسية...</p>
                </Link>
            </div>
        </div>
    );
}
 
export default NotFound;