import { useState } from "react";
import Quality from "./Quality";
import Speed from "./Speed";

export const enum PlaybackMenu {
  speed = "speed",
  quality = "quality",
  closed = "",
}

const Playback = ({
  isVisible,
  updatePlaybackRate,
  updatePlaybackQuality,
}: {
  isVisible: boolean;
  updatePlaybackRate: (val: number) => void;
  updatePlaybackQuality: (val: string) => void;
}) => {
  const [openMenu, setOpenMenu] = useState<string>("");

  return (
    <div className="ea-playback">
      <Speed
        isVisible={isVisible}
        updatePlaybackRate={updatePlaybackRate}
        setOpenMenu={setOpenMenu}
        openMenu={openMenu}
      />
      <Quality
        isVisible={isVisible}
        updatePlaybackQuality={updatePlaybackQuality}
        setOpenMenu={setOpenMenu}
        openMenu={openMenu}
      />
    </div>
  );
};

export default Playback;
