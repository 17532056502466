const Spinner = () => {
  return (
    <div className="d-flex justify-content-center pt-5" style={{height: '20vh'}}>
      <div className="spinner-border text-ea-primary" style={{fontWeight: "bold", width: "5rem", height: "5rem" }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
 
export default Spinner;