import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../hooks/BaseUrl";

import { userData } from "../../store/slices/storeSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { getFileUrl, isEmailValid, isNameValid, isPhoneValid } from "../../hooks/utils";
import GoogleIcon from "../../assets/images/google-icon.svg";
import FacebookIcon from "../../assets/images/facebook-icon.svg";
import { auth } from "../App";
import { signInWithCustomToken } from "firebase/auth";
import { endpoints } from "../../hooks/endpoints";
import { useAppDispatch } from "../../store/hooks";

const RegistrationForm = () => {
  const username2 = useRef("");
  const email = useRef("");
  const school = useRef("");
  const city = useRef("");
  const firstName = useRef("");
  const fatherPhone = useRef("");
  const motherPhone = useRef("");
  const password2 = useRef("");
  const [responseMsg2, setResponseMsg2] = useState();
  const [isLoginPending, setIsLoginPending] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [arabicAlert, setArabicAlert] = useState(false);
  const [isGoogleWindowOpen, setIsGoogleWindowOpen] = useState(false);
  const [isLoginWithFacebook, setIsLoginWithFacebook] = useState(false);

  const removeArabicChars = (e) => {
    let str = e.target.value;
    const newStr = str.replaceAll(/[\u0600-\u06FF]/g, "");
    e.target.value = newStr;
    setArabicAlert(str !== newStr);
    //create an alert div here
    // if (newStr !== str) alert("لا يمكن إدخال الحروف العربية في هذة الخانة");
  };

  useEffect(() => {
    if (!arabicAlert) return;
    setTimeout(() => {
      setArabicAlert(false);
    }, 5000);
  }, [arabicAlert]);

  const handleRegistrationSubmit = (e) => {
    e.preventDefault();

    const checkName = isNameValid(firstName.current.value);
    if (!checkName.isValid) {
      firstName.current.style = "border: 1px solid red";
      return setResponseMsg2(checkName.message);
    } else {
      firstName.current.style = "";
    }

    const checkPhone = isPhoneValid(username2.current.value);
    if (!checkPhone.isValid) {
      username2.current.style = "border: 1px solid red";
      return setResponseMsg2(checkPhone.message);
    } else {
      username2.current.style = "";
    }

    const checkFPhone = isPhoneValid(fatherPhone.current.value);
    if (!checkFPhone.isValid) {
      fatherPhone.current.style = "border: 1px solid red";
      return setResponseMsg2(checkFPhone.message);
    } else {
      fatherPhone.current.style = "";
    }

    const checkMPhone = isPhoneValid(motherPhone.current.value);
    if (!checkMPhone.isValid) {
      motherPhone.current.style = "border: 1px solid red";
      return setResponseMsg2(checkMPhone.message);
    } else {
      motherPhone.current.style = "";
    }

    const checkEmail = isEmailValid(email.current.value);
    if (!checkEmail.isValid) {
      email.current.style = "border: 1px solid red";
      return setResponseMsg2(checkEmail.message);
    } else {
      email.current.style = "";
    }

    setIsLoginPending(true);
    const credentials = {
      username: username2.current.value,
      password: password2.current.value,
      email: email.current.value,
      father_phone: fatherPhone.current.value,
      mother_phone: motherPhone.current.value,
      school: school.current.value,
      city: city.current.value,
      first_name: firstName.current.value,
    };
    fetch(endpoints.createUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result", result);
        if (result.status === 400) {
          setResponseMsg2(result.message);
        } else if (result.token) {
          signInWithCustomToken(auth, result.firebase_token)
            .then((userCredential) => {
              // Signed in
              setIsLoginPending(false);
              const user = userCredential.user;
              dispatch(userData(result));
              localStorage.setItem(
                `${window.location.hostname}_user`,
                JSON.stringify(result)
              );
              console.log("User Logged In");
              navigate("/complete-info");
              // ...
            })
            .catch((error) => {
              setIsLoginPending(false);
              const errorCode = error.code;
              const errorMessage = error.message;
              throw new Error(errorMessage);
              // ...
            });
        } else if (!result.is_valid) {
          setIsLoginPending(false);
          username2.current.style = "";
          fatherPhone.current.style = "";
          motherPhone.current.style = "";
          firstName.current.style = "";
          email.current.style = "";

          if (result.field === "phone") {
            username2.current.style = "border: 1px solid red";
            setResponseMsg2(result.message);
          }
          else if (result.field === "father_phone") {
            fatherPhone.current.style = "border: 1px solid red";
            setResponseMsg2(result.message);
          }
          else if (result.field === "mother_phone") {
            motherPhone.current.style = "border: 1px solid red";
            setResponseMsg2(result.message);
          }
          else if (result.field === "name") {
            firstName.current.style = "border: 1px solid red";
            setResponseMsg2(result.message);
          }
          else if (result.field === "email") {
            email.current.style = "border: 1px solid red";
            setResponseMsg2(result.message);
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoginPending(false);
        setResponseMsg2("حدث خطأ");
      });
  };

  const validateGoogleTokenOnServer = (code, currentPageUrl) => {
    setIsLoginPending(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code,
      url: currentPageUrl,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(endpoints.googleSocialLogin, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "credentialResponse");
        if (result.token) {
          signInWithCustomToken(auth, result.firebase_token)
            .then((userCredential) => {
              // Signed in
              setIsLoginPending(false);
              const user = userCredential.user;
              console.log({ user });
              dispatch(userData(result));
              localStorage.setItem(
                `${window.location.hostname}_user`,
                JSON.stringify(result)
              );
              setIsGoogleWindowOpen(false);
              navigate("/complete-info");
              // ...
            })
            .catch((error) => {
              setIsLoginPending(false);
              const errorCode = error.code;
              const errorMessage = error.message;
              throw new Error(errorMessage);
              // ...
            });
        } else {
          setIsLoginPending(false);
          const erMsg = document.getElementById("ea-err-msg2");
          erMsg.classList.add(
            "alert",
            "alert-danger",
            "mt-3",
            "align-self-center",
            "text-center",
            "rounded-ea"
          );
          erMsg.classList.remove("invisible");
          setResponseMsg2("الإيميل مش صح");
          setIsGoogleWindowOpen(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setResponseMsg2("حدث خطأ");
        setIsGoogleWindowOpen(false);
      });
  };

  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (GoogleResponse) => {
      console.log("Login Success:", GoogleResponse);
      validateGoogleTokenOnServer(GoogleResponse.code, window.location.origin);
    },
    onError: (error) => {
      console.log("Login Failed:", error);
      setResponseMsg2("حدث خطأ");
      setIsGoogleWindowOpen(false);
    },
    onNonOAuthError: () => {
      setIsGoogleWindowOpen(false);
    },
  });

  return (
    <form
      onSubmit={handleRegistrationSubmit}
      id="workshop-booking-form"
      className="m-3 bg-transparent"
    >
      <div className="d-flex">
        <div style={{ width: "45%", margin: "2%" }}>
          <div className="form-floating mb-2 text-ea-primary">
            <input
              type="text"
              id="firstName"
              name="firstName"
              // minLength={15}
              className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
              placeholder=""
              ref={firstName}
              // onChange={(e) => removeArabicChars(e)}
              required
            />
            <label htmlFor="floatingInput">الإسم رباعي</label>
          </div>
          <div className="form-floating mb-2 text-ea-primary">
            <input
              type="text"
              id="fatherPhone"
              name="fatherPhone"
              // maxLength={11}
              // minLength={11}
              className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
              placeholder=""
              ref={fatherPhone}
              onChange={(e) => removeArabicChars(e)}
              required
            />
            <label htmlFor="floatingInput">تليفون الأب</label>
          </div>
          <div className="form-floating mb-2 text-ea-primary">
            <input
              type="text"
              id="city"
              name="city"
              className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
              placeholder=""
              ref={city}
              // onChange={(e) => removeArabicChars(e)}
              required
            />
            <label htmlFor="floatingInput">المحافظة</label>
          </div>
          <div className="form-floating mb-2 text-ea-primary">
            <input
              type="text"
              id="email"
              name="email"
              className="form-control
                rounded-0
                text-lighea-primaryt
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
              placeholder=""
              ref={email}
              onChange={(e) => removeArabicChars(e)}
              required
            />
            <label htmlFor="floatingInput">الإيميل</label>
          </div>
        </div>
        <div style={{ width: "45%", margin: "2%" }}>
          <div className="form-floating mb-2 text-ea-primary">
            <input
              type="text"
              id="username2"
              name="username2"
              // maxLength={11}
              // minLength={11}
              className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
              placeholder=""
              ref={username2}
              onChange={(e) => removeArabicChars(e)}
              required
            />
            <label htmlFor="floatingInput">التلفون (به واتساب)</label>
          </div>
          <div className="form-floating mb-2 text-ea-primary">
            <input
              type="text"
              id="motherPhone"
              name="motherPhone"
              // maxLength={11}
              // minLength={11}
              className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
              placeholder=""
              ref={motherPhone}
              onChange={(e) => removeArabicChars(e)}
              required
            />
            <label htmlFor="floatingInput">تليفون الأم</label>
          </div>
          <div className="form-floating mb-2 text-ea-primary">
            <input
              type="text"
              id="school"
              name="school"
              className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
              placeholder=""
              ref={school}
              // onChange={(e) => removeArabicChars(e)}
              required
            />
            <label htmlFor="floatingInput">المدرسة</label>
          </div>
          <div className="form-floating mb-2 text-ea-primary">
            <input
              type="password"
              id="password2"
              name="password2"
              className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
              placeholder="***"
              ref={password2}
              onChange={(e) => removeArabicChars(e)}
              required
            />
            <label htmlFor="floatingInput">كلمة السر</label>
          </div>
        </div>
      </div>
      <div className="social-login-container">
        <button
          type="button"
          className="btn google-login rounded-ea text-light social-login-btns"
          disabled={isGoogleWindowOpen}
          style={{
            pointerEvents: isGoogleWindowOpen ? "none" : "auto",
            opacity: isGoogleWindowOpen ? "0.5" : "1",
          }}
          onClick={() => {
            loginWithGoogle();
            setIsGoogleWindowOpen(true);
          }}
        >
          التسجيل بجوجل{" "}
          <img
            style={{ margin: "0 5px", justifySelf: "center" }}
            src={GoogleIcon}
            alt="google"
          />
        </button>
        {/* <a
          style={{
            pointerEvents: isLoginWithFacebook ? "none" : "auto",
            opacity: isLoginWithFacebook ? "0.5" : "1",
          }}
          className="btn facebook-login rounded-ea text-light social-login-btns"
          href={`https://www.facebook.com/v20.0/dialog/oauth?client_id=3331531460486266&redirect_uri=${window.location.origin}${window.location.pathname}`}
        >
          التسجيل بفيسبوك{" "}
          <img
            style={{ margin: "0 5px", justifySelf: "center" }}
            src={FacebookIcon}
            alt="facebook"
          />
        </a> */}
      </div>
      {!isLoginPending && (
        <button
          type="submit"
          className="btn rounded-ea ea-btn text-light w-100"
        >
          إنشاء
        </button>
      )}
      {isLoginPending && (
        <button
          disabled
          className="btn rounded-ea ea-btn text-light w-100"
          type="submit"
        >
          <span
            className="spinner-border spinner-border-sm ms-2 me-2"
            role="status"
            aria-hidden="true"
          ></span>
          جاري التسجيل...
        </button>
      )}
      {responseMsg2 && (
        <div
          id="ea-err-msg"
          className="border-warning alert alert-warning mt-3 align-self-center text-dark rounded-ea"
          role="alert"
        >
          {responseMsg2}
        </div>
      )}
      {arabicAlert && (
        <div
          id="ea-err-msg"
          className="border-danger rounded-ea alert alert-danger mt-3 align-self-center text-dark"
          role="alert"
        >
          لا يمكن إدخال الحروف العربية في هذة الخانة
        </div>
      )}
    </form>
  );
};

export default RegistrationForm;
