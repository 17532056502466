import { useEffect, useRef, useState } from "react";
import useCheckIsMobile from "../components/lesson/lessonRefactor/utils/useCheckIsMobile";
import { LessonDataI } from "../components/quiz/typesAndEnums";
import { endpoints } from "./endpoints";

const useEliteVideoPlayer = ({
  vidId,
  expirtDate,
  lessonData,
}: {
  vidId: string;
  expirtDate: number;
  lessonData: LessonDataI;
}) => {
  const playerId = vidId;
  const playerRef = useRef(null);
  const [initialPlay, setInitialPlay] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [fallbackFullScreen, setFallbackFullScreen] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [volume, setVolume] = useState(100);
  const [muted, setMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [decremented, setDecremented] = useState(false);
  const { isMobile } = useCheckIsMobile();

  useEffect(() => {
    if (decremented) {
      const userInLocalStorage = JSON.parse(
        localStorage.getItem(`${window.location.hostname}_user`)
      );

      // Check if video progress is 80% or more
      if (
        //&& progress <= 85
        JSON.parse(lessonData.videos_data).length < 2 &&
        expirtDate
      ) {
        setDecremented(true);
        // Send fetch request to the backend API
        fetch(endpoints.videoView, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInLocalStorage.token}`,
          },
          body: JSON.stringify({
            lesson_id: lessonData.lesson_id,
          }),
          redirect: "follow",
        })
          .then((response) => {
            // Handle the response
            console.log(response);
            console.log(
              "video view",
              `80% of video reached for lesson id ${lessonData.lesson_id}`
            );
            setDecremented(true);
          })
          .catch((error) => {
            // Handle the error
            console.log(error);
          });
      }
    }
  }, [decremented]);

  useEffect(() => {
    const timeUpdater = setInterval(() => {
      const currentVidTime = playerRef.current?.getCurrentTime();
      const totalVidTime = playerRef.current?.getDuration();
      if (currentVidTime) {
        setCurrentTime(currentVidTime);
        const el = document.getElementById(
          `timeline-${playerId}`
        ) as HTMLInputElement;
        el.value = currentVidTime;
        if ((currentVidTime / totalVidTime) * 100 > 80 && !decremented) {
          setDecremented(true);
        }
      }
    }, 100);

    return () => {
      clearInterval(timeUpdater);
    };
  }, []);

  document.addEventListener("fullscreenchange", () => {
    const doc = document as Document & {
      webkitFullscreenElement: Element;
    };
    if (
      doc.fullscreenElement !== null ||
      doc.webkitFullscreenElement !== null
    ) {
      setFullScreen(true);
    } else {
      setFullScreen(false);
      setFallbackFullScreen(false);
    }
  });

  const getReference = (e) => {
    console.log("testPlayer", e.target);
    setPlayerReady(true);
    playerRef.current = e.target;
    setTotalTime(e.target.getDuration());
    document.addEventListener("keydown", (event) => {
      switch (event.key) {
        case " ":
          // togglePlayPause();
          event.preventDefault();
          break;
        case "m":
          event.preventDefault();
          // toggleMuteState();
          break;
        case "ArrowLeft":
          event.preventDefault();
          // skipTime(-10);
          break;
        case "ArrowRight":
          event.preventDefault();
          // skipTime(10);
          break;
      }
    });
    e.target.seekTo(0);
    e.target.pauseVideo();
  };

  const toggleFullScreen = () => {
    const videoElement = document.getElementById(
      `video-${playerId}`
    ) as HTMLVideoElement & {
      mozRequestFullScreen: () => void;
      webkitRequestFullscreen: () => void;
      msRequestFullscreen: () => void;
    };

    if (!fullScreen) {
      setFullScreen(true);

      if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      } else {
        setFallbackFullScreen(true);
        window.scrollTo(0, 0);
        document.body.style.overflow = "hidden";
        document.body.style.height = "100vh";
      }
    } else {
      setFullScreen(false);

      const doc = document as Document & {
        webkitExitFullscreen: () => void;
        mozCancelFullScreen: () => void;
        msExitFullscreen: () => void;
      };

      if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      } else if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      } else {
        setFallbackFullScreen(false);
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    }
  };

  const togglePlayPause = () => {
    const player = playerRef.current;
    if (player.getPlayerState() === 1) {
      player.pauseVideo();
      setPlayVideo(false);
    } else {
      player.playVideo();
      setPlayVideo(true);
    }
  };

  const toggleMuteState = () => {
    if (muted) {
      setMuted(false);
      const player = playerRef.current;
      player.unMute();
    } else {
      setMuted(true);
      const player = playerRef.current;
      player.mute();
    }
  };

  const skipTime = (value) => {
    const player = playerRef.current;
    player.seekTo(player.getCurrentTime() + value);
  };

  const updateVolume = (value) => {
    const player = playerRef.current;
    if (player.isMuted()) {
      setMuted(false);
      player.unMute();
    }
    setVolume(value);
    player.setVolume(value);
  };

  const updatePlaybackRate = (value: number) => {
    playerRef.current.setPlaybackRate(value);
  };

  const updatePlaybackQuality = (value: string) => {
    playerRef.current.setPlaybackQuality(value);
  };

  const updateTimeline = (e) => {
    setCurrentTime(+e.target.value);
    playerRef.current.seekTo(+e.target.value);
  };

  const isVisible = isMobile ? fullScreen : true;

  return {
    isMobile,
    isVisible,
    playerId,
    playerRef,
    playerReady,
    fullScreen,
    playVideo,
    volume,
    muted,
    currentTime,
    totalTime,
    initialPlay,
    fallbackFullScreen,
    setInitialPlay,
    skipTime,
    togglePlayPause,
    toggleMuteState,
    updateVolume,
    updatePlaybackRate,
    updatePlaybackQuality,
    updateTimeline,
    toggleFullScreen,
    getReference,
  };
};

export default useEliteVideoPlayer;
