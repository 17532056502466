import { useEffect, useState } from "react";
import {
  invokeUserRefetch,
  setCurrentHwProgress,
} from "../../../store/slices/storeSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { handleSubmitHwToServer } from "../utils/handleSubmitHwToServer";
import { ErrorTags, reportAppError } from "../../../errorReporter";
import useAssessmentController from "../../../hooks/useAssessmentController";
import { useQueryClient } from "@tanstack/react-query";
import { HomeworkData, LessonDataI, QuestionData } from "../typesAndEnums";

const useHomeworkHandler = ({ lessonData }: { lessonData: LessonDataI }) => {
  const [hwData, setHomeworkData] = useState<HomeworkData>();
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { getResult } = useAssessmentController();
  const { currentTeacher, currentHwProgress } = useAppSelector(
    (state) => state.store
  );

  const handleSubmitHomework = async () => {
    const result = await getResult({
      userAnswers: currentHwProgress,
      questions: lessonData?.hw_questions,
    });

    await handleSubmitHwToServer({
      lessonData,
      result,
      setIsSubmitPending,
      setSubmitError,
    });

    if (!submitError && !isSubmitPending) {
      dispatch(invokeUserRefetch());
      queryClient.invalidateQueries({
        queryKey: ["lesson"],
      });
      localStorage.removeItem(`hw_${lessonData?.hw_id}_user_answers`);
    }
  };

  const getLastProgress = () => {
    const previousProgress = JSON.parse(
      localStorage.getItem(`hw_${lessonData?.hw_id}_user_answers`) ?? "{}"
    );

    if (previousProgress) {
      dispatch(
        setCurrentHwProgress({
          ...previousProgress,
        })
      );
    }
  };

  let questionsData: QuestionData[] = lessonData?.hw_questions;

  let maxScore = null;
  let score = null;
  let percentage = null;

  if (lessonData?.hw_result) {
    try {
      const qRes = JSON.parse(lessonData?.hw_result ?? "{}");

      maxScore = +qRes.maxScore;
      score = +qRes.score;
      percentage = (+qRes.score / +qRes.maxScore) * 100;
      questionsData = questionsData.map((q) => ({
        ...q,
        userAnswer: qRes[q.question_id]?.answer,
        isCorrect: qRes[q.question_id]?.correct,
      }));
    } catch (error) {
      reportAppError({
        e: error as Error,
        info: "error while parsing past hw trial data",
        errorTag: ErrorTags.LessonScreen,
      });
    }
  }

  useEffect(() => {
    getLastProgress();
    setHomeworkData({
      score,
      maxScore,
      percentage,
      isEnded: !!lessonData?.hw_result,
      isStarted: true,
      questions: questionsData,
    });
  }, [lessonData]);

  return {
    ...hwData,
    handleSubmitHomework,
    isSubmitPending,
    submitError,
    currentTeacher,
  };
};

export default useHomeworkHandler;
