const BASE_URL = process.env.REACT_APP_API_URL;

export const endpoints = {
  /* GET Requests */
  // User
  isTeacher: `${BASE_URL}/elite/v1/is-teacher`,
  userPurchases: `${BASE_URL}/elite/v3/user-purchases`,
  accountPage: `${BASE_URL}/elite/v2/account-page`,
  getBankCats: `${BASE_URL}/elite/v1/get-bank-cats`,
  wallet: `${BASE_URL}/elite/v1/wallet`,
  checkInfo: `${BASE_URL}/elite/v2/check-info`,
  teacehrDataQuizReport: `${BASE_URL}/elite/v1/teacher-data-for-quiz-report`,
  checkSession: `${BASE_URL}/elite/v1/check-session`,

  // New User Request
  getUser: `${BASE_URL}/elite/v1/get-user`,
  getPassResetKey: `${BASE_URL}/elite/v1/pass-reset`,
  changePassWithResetKey: `${BASE_URL}/elite/v1/pass-reset-key`,
  quizGrades: `${BASE_URL}/elite/v1/quiz-grades`,
  hwProgress: `${BASE_URL}/elite/v1/get-hw-progress`,
  invoices: `${BASE_URL}/elite/v1/invoices`,

  // Portal
  teacherLessons: `${BASE_URL}/elite/v1/teacher-lessons`,
  getTeacherCats: `${BASE_URL}/elite/v1/get-teacher-cats`,
  // getPortal: `${BASE_URL}/elite/v1/get-portal`,
  teachersInfo: `${BASE_URL}/elite/v1/teachersinfo2`,
  teachersInfo1: `${BASE_URL}/elite/v1/teachersinfo`,
  teacherData: `${BASE_URL}/elite/v3/teacherdata`,
  getTeacherCatLessons: `${BASE_URL}/elite/v1/get-teacher-cats-lessons`,
  sidebarLessons: `${BASE_URL}/elite/v1/sidebarlessons`,
  // New Portal Request
  getPortal: `${BASE_URL}/elite/v1/get-portal`,

  // Single GET Requests
  lesson: `${BASE_URL}/elite/v1/lesson`, // Cached
  lessonV2: `${BASE_URL}/elite/v1/get-lesson`, // Cached
  teacherQuizzes: `${BASE_URL}/elite/v1/teacher-quizzes`, // Cached
  studentsList: `${BASE_URL}/elite/v1/students-list`, // Cached
  teacherSales: `${BASE_URL}/elite/v1/my-sales`, // Cached
  teacherSalesByProduct: `${BASE_URL}/v1/invoices/by-product`, // Cached
  teacherSalesByCategory: `${BASE_URL}/v1/invoices/by-category`, // Cached
  getBankQuestions: `${BASE_URL}/elite/v1/get-bank-questions`, // Cached
  teacherCatsQzsHwsLsns: `${BASE_URL}/elite/v1/teacher-cats-qzs-hws-payments`,
  getStudentData: `${BASE_URL}/elite/v1/student-data`,
  qzHwResultTeacehr: `${BASE_URL}/elite/v1/quiz-hw-result-teacher`,
  invoice: `${BASE_URL}/elite/v1/invoice`,
  getGrade: `${BASE_URL}/elite/v1/grade`,
  checkCoupon: `${BASE_URL}/elite/v1/check-coupon`,
  getAttendanceReport: `${BASE_URL}/elite/v1/get-offline-attendance`,

  // POST Requests
  updateMissingInfo: `${BASE_URL}/elite/v1/update-missing-info`,
  updateMissingInfo2: `${BASE_URL}/elite/v2/update-missing-info`,
  updateUserInfo: `${BASE_URL}/elite/v1/update-user-info`,
  deleteLesson: `${BASE_URL}/elite/v1/delete-lesson`,
  changePassword: `${BASE_URL}/elite/v1/change-password`,
  aiAssistant: `${BASE_URL}/elite/v1/ai-assistant`,
  vertexAiAssistant: `${BASE_URL}/elite/v1/vertex-ai-assistant`,
  createHomework: `${BASE_URL}/elite/v1/create-homework`,
  createLesson: `${BASE_URL}/elite/v1/create-lesson`,
  createQuiz: `${BASE_URL}/elite/v1/create-quiz`,
  createQuestion: `${BASE_URL}/elite/v1/create-question`,
  editGetLesson: `${BASE_URL}/elite/v1/single-lesson-edit`,
  editLesson: `${BASE_URL}/elite/v1/edit-lesson`,
  editGetQuiz: `${BASE_URL}/elite/v1/single-quiz-edit`,
  editQuiz: `${BASE_URL}/elite/v1/edit-quiz`,
  updateProfile: `${BASE_URL}/elite/v1/change-profile-data`,
  deleteBankQuestion: `${BASE_URL}/elite/v1/delete-bank-question`,
  createBankTag: `${BASE_URL}/elite/v1/create-bank-tag`,
  setPortal: `${BASE_URL}/elite/v1/set-portal-teacher`,
  // signIn: `${BASE_URL}/jwt-auth/v1/token`,
  // me: `${BASE_URL}/jwt-auth/v1/token/validate`,
  signIn: `${BASE_URL}/jwt/v1/generate`,
  me: `${BASE_URL}/jwt/v1/me`,
  googleSocialLogin: `${BASE_URL}/elite/v1/google-social-login`,
  facebookSocialLogin: `${BASE_URL}/elite/v1/facebook-social-login`,
  createUser: `${BASE_URL}/elite/v2/create-user`,
  manageTeacherCats: `${BASE_URL}/elite/v1/manage-new-teacher-cats`,
  manageTeacherOfflineGroups: `${BASE_URL}/elite/v1/manage-teacher-offline-groups`,
  changeStudentData: `${BASE_URL}/elite/v1/change-student-data`,
  fawryPurchase: `${BASE_URL}/elite/v1/fawrypurchase`,
  codePurchase: `${BASE_URL}/elite/v1/codepurchase`,
  freePurchase: `${BASE_URL}/elite/v1/freepurchase`,
  openLessonForStudents: `${BASE_URL}/elite/v1/batch-open-lesson`,
  walletPurchase: `${BASE_URL}/elite/v1/walletpurchase`,
  submitHomework: `${BASE_URL}/elite/v1/submit-homework`,
  submitQuiz: `${BASE_URL}/elite/v1/submit-quiz`,
  videoView: `${BASE_URL}/elite/v1/videoview`,
  aws: `${BASE_URL}/elite/v1/img-file-upload`,
  imageUpload: `${BASE_URL}/elite/v1/img-file-upload`,
  // drive: `${BASE_URL}/elite/v1/drive`,
  drive: `${BASE_URL}/elite/v1/aws`,
  eliteUpload: `${BASE_URL}/elite/v1/elite-upload-to-server`,
  createCoupons: `${BASE_URL}/elite/v1/create-teacher-coupons`,
  submitAttendance: `${BASE_URL}/elite/v1/submit-attendance`,
};
