import { useState } from "react";
import { endpoints } from "../../../hooks/endpoints";
import { getToken } from "../../../hooks/utils";

const FawryPurchase = ({ lessonData, isMobile }) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const handlePurchase = () => {
    setIsPending(true);
    fetch(
      endpoints.fawryPurchase +
        "?lesson=" +
        lessonData.lesson_id +
        "&ismobile=" +
        isMobile,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken()
        },
        // signal: abortCont.signal
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("this is result: ", result);
        setData(result);
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err);
        setError("حدث خطأ");
        setIsPending(false);
      });
  };

  return (
    <div className="d-block">
      <div className="mb-3">
        {!isPending && (
          <button
            className="rounded-ea btn ea-btn m-auto"
            onClick={handlePurchase}
          >
            طلب كود الدفع
          </button>
        )}
        {isPending && (
          <button
            className="rounded-ea btn ea-btn m-auto"
            type="button"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm mx-2"
              role="status"
              aria-hidden="true"
            ></span>
            طلب كود الدفع...
          </button>
        )}
        {data && (
          <div className="d-flex">
            <div
              className="rounded-ea mx-auto my-2 alert alert-success responsive-purchase-fawry"
              role="alert"
            >
              <p>كود فوري : {data.fawryCode}</p>
              <p>تاريخ أنتهاء صلاحية الكود : {data.expireDate}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FawryPurchase;
