const Xvast = () => {
  return (
    <div className="container">
      <div className="alert alert-danger rounded-ea my-2 fs-5" role="alert">
        هذا المحتوى محمي , يجب تحميل متصفح Xvast لمشاهدة الحصة منه, متصفح Xvast
        يشبه جوجل كروم و يمكنك إستخدامه للدخول على أي موقع , بعد التحميل افتح
        متصفح Xvast و ادخل منه على الموقع كأنك تستعمل جوجل كروم{" "}
        {window.location.hostname !== "mohamedyehyafr.com" ? (
          <strong>أو يمكنك مشاهدة الحصة من الهاتف</strong>
        ) : (
          ""
        )}
      </div>
      <a
        className="btn ea-btn m-2 rounded-ea"
        href="https://www.drm-x.com/download/XvastInstaller.exe"
      >
        تحميل Xvast للوندوز
      </a>
      {window.location.hostname === "mohamedyehyafr.com" && (
        <>
          <a
            className="btn ea-btn m-2 rounded-ea"
            href="https://www.drm-x.com/download/Xvast.apk"
          >
            تحميل Xvast للأندرويد
          </a>
          <a
            className="btn ea-btn m-2 rounded-ea"
            href="https://itunes.apple.com/us/app/xvast/id1239299799?mt=8"
          >
            تحميل Xvast للأيفون
          </a>
        </>
      )}
      <a
        className="btn ea-btn m-2 rounded-ea"
        href="https://www.drm-x.com/download/Xvast.app.zip"
      >
        تحميل Xvast للماك
      </a>
    </div>
  );
};

export default Xvast;
