const InitialPlayButton = ({
  togglePlayPause,
  initialPlay,
  setInitialPlay,
}: {
  togglePlayPause: () => void;
  initialPlay: boolean;
  setInitialPlay: (val: boolean) => void;
}) => {
  const handleStart = () => {
    togglePlayPause();
    setInitialPlay(true);
  };

  return (
    <div
      className={`ea-video-big-play-btn-bg ${initialPlay ? "invisible" : ""}`}
    >
      <button onClick={handleStart}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="white"
          className="bi bi-play-circle ea-video-big-play-btn"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
        </svg>
      </button>
    </div>
  );
};

export default InitialPlayButton;
