import { useState } from "react";
import { endpoints } from "../../../hooks/endpoints";
import { getToken } from "../../../hooks/utils";
import Animation from "../../Animation";
import { useAppDispatch } from "../../../store/hooks";
import { invokeUserRefetch } from "../../../store/slices/storeSlice";
import Spinner from "../../Spinner";
import { LessonDataI } from "../../quiz/typesAndEnums";

const FreePurchase = ({ lessonData }: { lessonData: LessonDataI }) => {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const dispatch = useAppDispatch();

  const handleFreePurchase = (lesson_id: number) => {
    setIsPending(true);

    fetch(endpoints.freePurchase + "?lesson=" + lesson_id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result !== "not-free") {
          dispatch(invokeUserRefetch());
        } else {
          setError(result);
        }
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err);
        setError("حدث خطأ");
        setIsPending(false);
      });
  };

  if (isPending) return <Spinner />;

  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <Animation>
      <div className="d-flex">
        <button
          onClick={() => handleFreePurchase(lessonData.lesson_id)}
          className="mx-auto my-3 btn ea-btn rounded-ea"
        >
          الإشتراك فالحصة مجاناً
        </button>
      </div>
    </Animation>
  );
};

export default FreePurchase;
