
const LessonPageContentArea = ({ children }) => {
  return (
    <div className="col-md-8 col-12 p-3">
      <div className="bg-white rounded-ea shadow pb-4">{children}</div>
    </div>
  );
};

export default LessonPageContentArea;
