import Spinner from "../Spinner";
import { useNavigate, useParams } from "react-router";
import NotFound from "../NotFound";
import { useEffect, useState } from "react";
import QuestionImg from "./QuestionImg";
import Animation from "../Animation";
import { endpoints } from "../../hooks/endpoints";
import { getToken } from "../../hooks/utils";

interface GradeQuestionI {
  id: number;
  content: string;
  img: string;
  weight: number;
  answers: string[];
  correct_answer: string;
  student_answer: string;
  correct: boolean;
}

interface GradeI {
  id: number;
  date: string;
  raw_data: string[];
  questions: GradeQuestionI[];
  hide_correct_answers: boolean;
}

const Grade = () => {
  const { id } = useParams();
  const [isPending, setIsPending] = useState<boolean>(true);
  const [error, setError] = useState(null);
  const [grade, setGrade] = useState<GradeI>(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${endpoints.getGrade}?grade=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setGrade(data);
        setIsPending(false);
        if (data && !data.raw_data) navigate("/grades");
      })
      .catch((err) => {
        setError(err);
        setIsPending(false);
      });
  }, []);

  return (
    <Animation>
      <div>
        <div className="responsive-grade mx-auto py-3">
          {grade && (
            <div className="p-3">
              <div className="d-flex">
                <img
                  className="image-fluid w-50 mx-auto"
                  src="https://drive.google.com/uc?id=11oWusZr8VM7oYq5axs4k4_WSJqcie-s2"
                  alt=""
                />
              </div>
              <div className="d-flex mb-3">
                <h3 className="m-auto">درجتك هي</h3>
              </div>
              <p className="fw-bold my-auto fs-5 m-2 text-center">
                {grade.raw_data
                  ? `${JSON.parse(grade.raw_data[0]).score} من ${
                      JSON.parse(grade.raw_data[0]).maxScore
                    }`
                  : ""}
              </p>
              <div
                className="progress rounded-ea responsive-quiz-grade mx-auto"
                style={{ height: "1.5rem", backgroundColor: "#ddd" }}
              >
                <div
                  className="progress-bar rounded-ea"
                  role="progressbar"
                  style={{
                    width: grade.raw_data
                      ? `${
                          (JSON.parse(grade.raw_data[0]).score /
                            JSON.parse(grade.raw_data[0]).maxScore) *
                          100
                        }%`
                      : "0px",
                    height: "1.5rem",
                  }}
                  aria-valuenow={
                    grade.raw_data
                      ? `${
                          (JSON.parse(grade.raw_data[0]).score /
                            JSON.parse(grade.raw_data[0]).maxScore) *
                          100
                        }%`
                      : "0px"
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          )}
          {grade &&
            grade.questions?.map((question: GradeQuestionI) => (
              <div
                key={question.id}
                className={`p-2 my-3 bg-white mx-4 border-ea-2 rounded-ea quiz-ended`}
              >
                <div className="rounded-ea d-flex">
                  {question && <QuestionImg src={question.img} alt="" />}
                </div>
                {question.content ? (
                  <>
                    {question.content.indexOf("\n") !== 1 ? (
                      question.content
                        .split("\n")
                        .map((p: string) => <p>{p}</p>)
                    ) : (
                      <p>{question.content}</p>
                    )}
                  </>
                ) : (
                  ""
                )}
                <div
                  className="my-2 btn-group w-100"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  {question.answers
                    ?.sort()
                    .reverse()
                    .map((answer) => (
                      <>
                        <input
                          type="radio"
                          className="btn-check"
                          name={question.id}
                          id={`btnradio-${question.id}-${answer}`}
                          value={answer}
                          autoComplete="off"
                          checked={
                            question.student_answer === answer ? true : null
                          }
                          // required={lessonData.must_answer_all === 'on' ? true : false}
                        />
                        <label
                          className="btn btn-outline-primary fw-bold"
                          htmlFor={`btnradio-${question.id}-${answer}`}
                        >
                          {answer}
                        </label>
                      </>
                    ))}
                </div>
                <div className="d-flex">
                  <div className="d-flex w-75 mx-auto">
                    <div className="mx-auto">
                      {question.correct && (
                        <div className="d-flex mx-1">
                          <div
                            style={{ maxHeight: "50px" }}
                            className="d-flex py-2 px-4 rounded-ea alert border-success alert-success mx-auto"
                            role="alert"
                          >
                            <p className="fw-bold m-auto">إجابة صحيحة</p>
                          </div>
                        </div>
                      )}
                      {!question.correct && (
                        <div className="d-flex mx-1">
                          <div
                            style={{ maxHeight: "50px" }}
                            className="d-felx py-2 px-4 rounded-ea alert border-danger alert-danger mx-auto"
                            role="alert"
                          >
                            {grade.hide_correct_answers ? (
                              <p className="fw-bold m-auto">إجابة خاطئة</p>
                            ) : (
                              <p className="fw-bold m-auto">{`الإجابة الصحيحة هي ${question.correct_answer}`}</p>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="d-flex mx-1">
                        <div
                          style={{ maxHeight: "50px" }}
                          className="d-felx py-2 px-4 rounded-ea alert alert-warning mx-auto border-warning"
                          role="alert"
                        >
                          <p className="m-auto fw-bold">
                            الدرجة: {question.weight}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {error && <NotFound />}
              </div>
            ))}
          {grade && grade === "Not the student" && <NotFound />}
          {isPending && <Spinner />}
        </div>
      </div>
    </Animation>
  );
};

export default Grade;
