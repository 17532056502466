
const Timer = ({
  totalTime,
  currentTime,
}: {
  totalTime: number;
  currentTime: number;
}) => {
  return (
    <div className="ea-duration-container">
      {`${totalTime > 3600 ? `${Math.floor(currentTime / 3600)}:` : ""}${String(
        Math.floor(currentTime / 60) % 60
      ).padStart(2, "0")}:${String(Math.floor(currentTime % 60)).padStart(
        2,
        "0"
      )} `}
      /
      {` ${totalTime > 3600 ? `${Math.floor(totalTime / 3600)}:` : ""}${String(
        Math.floor(totalTime / 60) % 60
      ).padStart(2, "0")}:${String(Math.floor(totalTime % 60)).padStart(
        2,
        "0"
      )}`}
    </div>
  );
};

export default Timer;
