import { useState } from "react";
import { PlaybackMenu } from "./Playback";

const Speed = ({
  isVisible,
  updatePlaybackRate,
  openMenu,
  setOpenMenu,
}: {
  isVisible: boolean;
  updatePlaybackRate: (e: any) => void;
  openMenu: string;
  setOpenMenu: (val: PlaybackMenu) => void;
}) => {
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);

  const handleSelectorChange = (speed: number) => {
    setPlaybackSpeed(speed);
    updatePlaybackRate(speed);
    setOpenMenu(PlaybackMenu.closed);
  };

  const handleOpenMenu = () => {
    openMenu === PlaybackMenu.speed
      ? setOpenMenu(PlaybackMenu.closed)
      : setOpenMenu(PlaybackMenu.speed);
  };

  if (!isVisible) return <></>;

  return (
    <div className="ea-playback-speed">
      <span onClick={handleOpenMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-fast-forward-fill mx-1"
          viewBox="0 0 16 16"
        >
          <path d="M7.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z" />
          <path d="M15.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z" />
        </svg>
        <span>{`${playbackSpeed}x`}</span>
      </span>
      <ul className={openMenu === PlaybackMenu.speed ? "selector-visible" : ""}>
        <li onClick={() => handleSelectorChange(0.5)}>0.5x</li>
        <li onClick={() => handleSelectorChange(1)}>1x</li>
        <li onClick={() => handleSelectorChange(1.25)}>1.25x</li>
        <li onClick={() => handleSelectorChange(1.5)}>1.5x</li>
        <li onClick={() => handleSelectorChange(1.75)}>1.75x</li>
        <li onClick={() => handleSelectorChange(2)}>2x</li>
      </ul>
    </div>
  );
};

export default Speed;
