import React from "react";

const Files = ({ files }: { files: string[] }) => {
  return (
    <div className="m-3" style={{ textAlign: "center" }}>
      {files &&
        files.length > 0 &&
        files[0] !== "{}" &&
        files.map((file, index) => (
          <a
            className="btn ea-btn rounded-ea my-2 mx-2 lesson-file-btn"
            style={{ width: "100%" }}
            href={file}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              className="bi bi-file-earmark-text-fill text-white"
              viewBox="0 0 16 16"
            >
              <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z" />
            </svg>{" "}
            <span>ملف رقم {index + 1}</span>
          </a>
        ))}
    </div>
  );
};

export default Files;
