import { useState } from "react";

const MobileControls = ({
  updatePlaybackQuality,
  updatePlaybackRate,
  updateVolume,
  muted,
  volume,
}: {
  updatePlaybackQuality: (val: string) => void;
  updatePlaybackRate: (val: number) => void;
  updateVolume: (val: number) => void;
  muted: boolean;
  volume: number;
}) => {
  const enum Controller {
    speed = "speed",
    quality = "quality",
    volume = "volume",
    closed = "",
  }
  const [openControls, setOpenControls] = useState<boolean>(false);
  const [currentController, setCurrentController] = useState<string>("");

  const handleToggleMenu = () => {
    setCurrentController(Controller.closed);
    setOpenControls((prev) => !prev);
  };

  const handleUpdateQuality = (quality: string) => {
    updatePlaybackQuality(quality);
    setCurrentController(Controller.closed);
    setOpenControls(false);
  };

  const handleUpdateSpeed = (speed: number) => {
    updatePlaybackRate(speed);
    setCurrentController(Controller.closed);
    setOpenControls(false);
  };

  const handleUpdateVolume = (volume: number) => {
    updateVolume(volume);
  };

  return (
    <div className="mobile-controls">
      <button className="mobile-controls-btn" onClick={handleToggleMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-gear-fill mx-1"
          viewBox="0 0 16 16"
        >
          <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
        </svg>
      </button>
      {openControls && (
        <div className="mobile-controls-lists-wrapper">
          {currentController === Controller.quality && (
            <ul>
              <li>
                <li onClick={() => handleUpdateQuality("medium")}>360p</li>
                <li onClick={() => handleUpdateQuality("large")}>480p</li>
                <li onClick={() => handleUpdateQuality("hd720")}>720p</li>
                <li onClick={() => handleUpdateQuality("hd1080")}>1080p</li>
              </li>
            </ul>
          )}
          {currentController === Controller.speed && (
            <ul>
              <li>
                <li onClick={() => handleUpdateSpeed(0.5)}>0.5x</li>
                <li onClick={() => handleUpdateSpeed(1)}>1x</li>
                <li onClick={() => handleUpdateSpeed(1.25)}>1.25x</li>
                <li onClick={() => handleUpdateSpeed(1.5)}>1.5x</li>
                <li onClick={() => handleUpdateSpeed(1.75)}>1.75x</li>
                <li onClick={() => handleUpdateSpeed(2)}>2x</li>
              </li>
            </ul>
          )}
          {currentController === Controller.volume && (
            <div className="mobile-controls-volume">
              <input
                onChange={(e) => handleUpdateVolume(+e.target.value)}
                type="range"
                min={0}
                max={100}
                step={1}
                value={muted ? 0 : volume}
                className="form-range range"
              />
            </div>
          )}
          <ul>
            <li
              className={currentController === Controller.speed ? "active" : ""}
              onClick={() => setCurrentController(Controller.speed)}
            >
              السرعة
            </li>
            <li
              className={
                currentController === Controller.quality ? "active" : ""
              }
              onClick={() => setCurrentController(Controller.quality)}
            >
              الجودة
            </li>
            {/* <li
              className={
                currentController === Controller.volume ? "active" : ""
              }
              onClick={() => setCurrentController(Controller.volume)}
            >
              Volume
            </li> */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileControls;
