import "plyr-react/plyr.css";
import { useEffect, useRef, useState } from "react";
import useCheckIsMobile from "../lesson/lessonRefactor/utils/useCheckIsMobile";
import { LessonDataI, VideoData, VideoProvider } from "../quiz/typesAndEnums";
import EliteVideoPlayer from "./EliteVideoPlayer/EliteVideoPlayer";
import Files from "./Files";
import VimeoVideo from "./VimeoVideo";
import Xvast from "./Xvast";

const VideosMap = ({
  videos,
  lessonData,
  expirtDate,
  files,
  isProtected,
  getVideoID,
  handleVideoView,
}: {
  videos: VideoData[];
  lessonData: LessonDataI;
  expirtDate: number;
  files: string[] | null;
  isProtected: boolean;
  getVideoID: (video: VideoData) => string;
  handleVideoView: () => void;
}) => {
  const [decrementView, setDecrementView] = useState(false);
  const { isIOS } = useCheckIsMobile();

  const playerRef = useRef(null);

  // useEffect(() => {
  //   applyWatermark();
  //   return () => {
  //     removeWatermark();
  //   };
  // }, []);

  useEffect(() => {
    if (decrementView) {
      handleVideoView();
    }

    if (playerRef.current && !decrementView) {
      setTimeout(() => {
        playerRef.current
          ? playerRef.current.plyr.on("timeupdate", (event) => {
              const duration = event.detail.plyr.duration;
              const currentTime = event.detail.plyr.currentTime;
              const progress = (currentTime / duration) * 100;
              if (progress > 80) {
                setDecrementView(true);
              }
            })
          : setDecrementView(false);
      }, 10000);
    }
  }, [decrementView, playerRef.current]);

  const videosMapper = (video: VideoData) => {
    switch (video.provider) {
      case VideoProvider.VIMEO:
        return <VimeoVideo videoUrl={getVideoID(video)} />;
      // case VideoProvider.PLYR:
      //   return (
      //     <Plyr
      //       source={{
      //         type: "video",
      //         sources: [
      //           {
      //             src: getVideoID(video),
      //             provider: "youtube",
      //           },
      //         ],
      //       }}
      //       ref={playerRef}
      //     />
      //   );
      case VideoProvider.BUNNY:
        return (
          <iframe
            src={`https://iframe.mediadelivery.net/embed/${
              getVideoID(video).split("play/")[1]
            }?autoplay=false&loop=false&muted=false&preload=true&responsive=true`}
            loading="lazy"
            style={{
              border: 0,
              top: 0,
              minHeight: "500px",
              height: "100%",
              width: "100%",
              pointerEvents: "auto",
            }}
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
            allowFullScreen={true}
          />
        );
      default:
        return (
          <EliteVideoPlayer
            vidId={getVideoID(video)}
            expirtDate={expirtDate}
            lessonData={lessonData}
          />
        );
    }
  };

  if (isProtected) return <Xvast />;

  return (
    <>
      <div className="pt-2 pe-4 ps-4 pb-4">
        {videos.map((video: VideoData) => (
          <div key={video.video_id}>
            <div
              className="w-100 my-2 p-2 bg-ea-base-2 border-ea rounded-ea text-ea-primary"
              style={{ textAlign: "center" }}
              role="alert"
            >
              <h5 className="d-inline">{video && video.video_title}</h5>
            </div>
            <div className="d-block">{videosMapper(video)}</div>
          </div>
        ))}
        <Files files={files} />
      </div>
    </>
  );
};

export default VideosMap;
