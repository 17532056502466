import { QuestionData } from "../typesAndEnums";
import QuestionMCQ from "./QuestionMCQ";

const AssessmentQuestions = ({
  assessmentMode,
  assessmentId,
  mustAnswerAll,
  hideCorrectAnswers,
  questions,
  isEnded,
}: {
  assessmentMode: string;
  assessmentId: number;
  mustAnswerAll: boolean;
  hideCorrectAnswers: boolean;
  questions: QuestionData[];
  isEnded: boolean;
}) => {
  return (
    <>
      {questions.map((question: QuestionData) => (
        <QuestionMCQ
          key={question.question_id}
          assessmentId={assessmentId}
          question={question}
          isEnded={isEnded}
          mustAnswerAll={mustAnswerAll}
          hideCorrectAnswers={hideCorrectAnswers}
          assessmentMode={assessmentMode}
        />
      ))}
    </>
  );
};

export default AssessmentQuestions;
