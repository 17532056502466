const extractVideoId = (url) => {
    let videoId = null;

    // Regular expression patterns for different YouTube URL formats
    const patterns = [/(?:\?|&)v=([^&]+)/, /youtu\.be\/([^?]+)/];

    // Iterate through the patterns and try to match them with the URL
    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        videoId = match[1];
        break; // Exit the loop once a match is found
      }
    }

    return videoId;
  };

  export default extractVideoId