import { useRef, useState } from "react";
import "../../styles/createQuiz.css";
import FileUploadProgressBar from "./FileUploadProgressBar";
import QuestionImg from "../quiz/QuestionImg";
import AddFromQuestionBank from "./AddFromQuestionBank";
import Separator from "../Separator";
import Animation from "../Animation";
import { endpoints } from "../../hooks/endpoints";

import {
  invokePortalRefetch,
  invokeUserRefetch,
} from "../../store/slices/storeSlice";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../App";
import { getFileUrl, getUID } from "../../hooks/utils";
import { useAppDispatch } from "../../store/hooks";

const CreateHomework = () => {
  const [questionsCount, setQuestionsCount] = useState([1]);
  const [questionsImages, setQuestionsImages] = useState({});
  const [successMsg, setSuccessMsg] = useState(null);
  const [publishing, setPublishing] = useState(false);
  const [fileProgress, setFileProgress] = useState(null);
  const hideCorrectAnswers = useRef();
  const randomizeQuestions = useRef();
  const mustAnswerAll = useRef();
  const [availableAnswers, setAvailableAnswers] = useState({});
  const dispatch = useAppDispatch();

  const getAnswersCount = (id) => {
    const answers = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    const answersCount = availableAnswers[id];
    const answersOptions = [];
    for (let i = 0; i < answersCount && i < 10; i++) {
      answersOptions.push(answers[i]);
    }
    return answersOptions;
  };

  const maxMinCount = (e) => {
    if (e.target.value > 10) {
      setAvailableAnswers((prev) => ({
        ...prev,
        [e.target.id]: 10,
      }));
      document.getElementById(e.target.id).value = 10;
    } else if (e.target.value < 2) {
      setAvailableAnswers((prev) => ({
        ...prev,
        [e.target.id]: 2,
      }));
      document.getElementById(e.target.id).value = 2;
    } else {
      setAvailableAnswers((prev) => ({
        ...prev,
        [e.target.id]: e.target.value,
      }));
    }
  };

  const handleUploadFb = (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    const imageRef = ref(storage, `questions-${new Date().getTime()}`);
    const uploadTask = uploadBytesResumable(imageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFileProgress(progress.toFixed(2));
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.log(error);
        alert("حدث خطأ");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const tempObject = {};
          tempObject[`hw_question_img_url_${e.target.dataset.id}`] =
            getFileUrl(downloadURL);
          setQuestionsImages({ ...questionsImages, ...tempObject });
          setFileProgress(null);
        });
      }
    );
  };

  const handleUpload = async (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0], e.target.files[0].name);

      const xhr = new XMLHttpRequest();

      // Track the progress of the upload
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          console.log(
            "uploadProgress",
            `Upload Progress: ${percentComplete.toFixed(2)}%`
          );
          if (percentComplete.toFixed(2) === "100.00") {
            setFileProgress(null);
          } else {
            setFileProgress(percentComplete.toFixed(2));
          }
          // You can update a progress bar or display the percentage as needed
        }
      });

      xhr.open("POST", endpoints.aws, true);
      // xhr.open("POST", endpoints.imageUpload, true);
      // xhr.open("POST", endpoints.eliteUpload, true);

      // Add the Authorization header with the token
      const token = JSON.parse(
        localStorage.getItem(`${window.location.hostname}_user`)
      ).token;
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            try {
              const data = JSON.parse(xhr.responseText);

              if (data.success) {
                // File uploaded successfully
                const tempObject = {};
                tempObject[`hw_question_img_url_${e.target.dataset.id}`] =
                  data.url;
                setQuestionsImages({ ...questionsImages, ...tempObject });
                console.log(data);
                setFileProgress(null);
              } else {
                // File upload failed
                alert("File upload failed. " + data.message);
                setFileProgress(null);
              }
            } catch (error) {
              alert("حدث خطأ");
              setFileProgress(null);
            }
          } else {
            // Server error
            alert("حدث خطأ");
            setFileProgress(null);
          }
        }
      };

      xhr.send(formData);
    } catch (error) {
      // Network error
      alert("An error occurred while uploading the file.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPublishing(true);
    try {
      const formData = new FormData(e.target);
      const formDataObject = {};
      for (const [key, value] of formData.entries()) {
        formDataObject[key] = value;
      }
      formDataObject["questionsCount"] = questionsCount;
      formDataObject["hideCorrectAnswers"] = hideCorrectAnswers.current.checked;
      formDataObject["randomizeQuestions"] = randomizeQuestions.current.checked;
      formDataObject["mustAnswerAll"] = mustAnswerAll.current.checked;

      const response = await fetch(endpoints.createHomework, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(`${window.location.hostname}_user`))
              .token
          }`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (!response.ok) {
        // Handle server errors or other issues
        console.error("Error submitting the form");
        return;
      } else {
        setPublishing(false);
        setSuccessMsg(true);
        dispatch(invokePortalRefetch());
        dispatch(invokeUserRefetch());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Animation>
      <div className="create-hw">
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="m-3 p-3 bg-white rounded-ea shadow"
        >
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            إنشاء واجب جديد
          </h3>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control rounded-ea"
              id="hw-title"
              name="title"
              placeholder="title"
              required
            />
            <label htmlFor="hw-title">عنوان الواجب</label>
          </div>
          {/* <div className="form-floating mb-3">
                    <input type="text" className="form-control rounded-ea" id="hw-description" name="description" placeholder="description" required />
                    <label htmlFor="hw-description">رسالة قبل بدء الواجب</label>
                </div> */}
          {/* <div className="form-floating mb-3">
                    <input type="number" className="form-control rounded-ea" id="hw-pass_score" name="pass_score" placeholder="pass_score" required />
                    <label htmlFor="hw-pass_score">درجة النجاح</label>
                </div> */}
          {/* <div className="form-floating mb-3">
                    <input type="number" className="form-control rounded-ea" id="hw_max_redo_count" name="hw_max_redo_count" placeholder="hw_max_redo_count" required />
                    <label htmlFor="hw_max_redo_count">عدد مرات دخول الامتحان للطالب</label>
                </div> */}
          <div className="form-check form-switch my-3">
            <input
              ref={hideCorrectAnswers}
              className="form-check-input"
              type="checkbox"
              id="hw-hide_correct_answers"
            />
            <label
              className="form-check-label ms-1"
              htmlFor="hw-hide_correct_answers"
            >
              إخفاء الإجابات الصحيحة
            </label>
          </div>
          <div className="form-check form-switch my-3">
            <input
              ref={randomizeQuestions}
              className="form-check-input"
              type="checkbox"
              id="hw-randomize_questions"
            />
            <label
              className="form-check-label ms-1"
              htmlFor="hw-randomize_questions"
            >
              إعادة ترتيب الأسئلة لكل طالب
            </label>
          </div>
          <div className="form-check form-switch my-3">
            <input
              ref={mustAnswerAll}
              className="form-check-input"
              type="checkbox"
              id="hw-must_answer_all"
            />
            <label
              className="form-check-label ms-1"
              htmlFor="hw-must_answer_all"
            >
              يجب حل جميع الأسئلة
            </label>
          </div>
          {questionsCount.map((question, index) => (
            <>
              <Separator />
              <h5 className="mb-3">
                <i>--- سؤال رقم {index + 1} ---</i>
              </h5>
              <AddFromQuestionBank
                questionNum={question}
                setQuestionsImages={setQuestionsImages}
                questionsImages={questionsImages}
                setAvailableAnswers={setAvailableAnswers}
                availableAnswers={availableAnswers}
                prefix={"hw_"}
              />
              <input
                id={`qid_${question}`}
                className="lesson_form_input form-control question_counter"
                type="hidden"
                name={`qid_${question}`}
              />
              <label
                className="form-label"
                htmlFor={`hw_question_img_input_${question}`}
              >
                صورة السؤال
              </label>
              <div>
                {fileProgress &&
                  fileProgress < 100 &&
                  fileProgress >= 0 &&
                  index + 1 === questionsCount.length && (
                    <FileUploadProgressBar fileProgress={fileProgress} />
                  )}
                <input
                  id={`hw_question_img_input_${question}`}
                  onChange={(e) => handleUploadFb(e)}
                  data-id={question}
                  className="elite-file lesson_form_input form-control mt-2"
                  type="file"
                  name={`hw_question_img_input_${question}`}
                />
                <br />
              </div>
              {questionsImages[`hw_question_img_url_${question}`] && (
                <QuestionImg
                  id={`hw-uploaded-image-${question}`}
                  src={questionsImages[`hw_question_img_url_${question}`]}
                  alt=""
                  classStyles="img-fluid w-100 rounded-ea border-ea-img mb-3"
                  border="3px solid #673de6"
                />
              )}
              <div
                id={`hw-uploaded-image-spinner-${question}`}
                className="spinner-border spinner"
                role="status"
                style={{ display: "none" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
              <input
                id={`hw_question_img_url_${question}`}
                value={questionsImages[`hw_question_img_url_${question}`]}
                className="lesson_form_input form-control question_counter"
                type="hidden"
                name={`hw_question_img_url_${question}`}
                required
              />
              <div className="mb-3">
                <label for="exampleFormControlTextarea1" className="form-label">
                  محتوى السؤال (اختياري)
                </label>
                <textarea
                  className="form-control"
                  id={`hw_question_textarea_${question}`}
                  name={`hw_question_textarea_${question}`}
                  rows="3"
                ></textarea>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control rounded-ea"
                  id={`hw_question_ans_count_${question}`}
                  name={`hw_question_ans_count_${question}`}
                  placeholder={`hw_question_ans_count_${question}`}
                  max={10}
                  min={2}
                  required
                  onChange={(e) => maxMinCount(e)}
                />
                <label htmlFor={`hw_question_ans_count_${question}`}>
                  عدد الإجابات
                </label>
              </div>
              <select
                id={`hw_question_ans_${question}`}
                className="form-select mb-3 rounded-ea"
                name={`hw_question_ans_${question}`}
                aria-label="Default select example"
                required
              >
                <option>الإجابة الصحيحة</option>
                {getAnswersCount(`hw_question_ans_count_${question}`).map(
                  (ans) => (
                    <option value={ans}>{ans}</option>
                  )
                )}
              </select>
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control rounded-ea"
                  id={`hw_question_weight_${question}`}
                  name={`hw_question_weight_${question}`}
                  placeholder={`hw_question_weight_${question}`}
                  required
                />
                <label htmlFor={`hw_question_weight_${question}`}>الدرجة</label>
              </div>
            </>
          ))}
          {questionsCount.length > 1 && (
            <div id="hw-remove_question" style={{ marginBottom: "10px" }}>
              <button
                disabled={
                  fileProgress && fileProgress < 100 && fileProgress >= 0
                    ? true
                    : false
                }
                onClick={(e) => {
                  e.preventDefault();
                  setQuestionsCount((prev) => [
                    ...prev.filter((val) => val !== prev[prev.length - 1]),
                  ]);
                }}
                id="hw-remove_question_btn"
                className="btn ea-btn rounded-ea"
                style={{
                  maxWidth: "30%",
                  borderRadius: "7px",
                  fontWeight: "bold",
                }}
              >
                - حذف سؤال
              </button>
            </div>
          )}
          <div id="hw-add_question" style={{ marginBottom: "10px" }}>
            <button
              disabled={
                fileProgress && fileProgress < 100 && fileProgress >= 0
                  ? true
                  : false
              }
              onClick={() =>
                setQuestionsCount((prev) => [...prev, prev.length + 1])
              }
              id="hw-add_question_btn"
              className="btn ea-btn rounded-ea"
              style={{
                maxWidth: "30%",
                borderRadius: "7px",
                fontWeight: "bold",
              }}
            >
              + أضف سؤال
            </button>
          </div>
          {!publishing && (
            <input
              type="submit"
              disabled={
                fileProgress && fileProgress < 100 && fileProgress >= 0
                  ? true
                  : false
              }
              id="hw-publish_new_hw_submit"
              className="ea-btn btn w-100 rounded-ea"
              value="حفظ"
            />
          )}
          {publishing && (
            <button
              className="ea-btn btn w-100 rounded-ea"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              جاري الحفظ...
            </button>
          )}
          {successMsg && (
            <div className="alert alert-success h-50 mt-2" role="alert">
              تم إنشاء الواجب يمكنك الان ربطه بالحصة
            </div>
          )}
        </form>
      </div>
    </Animation>
  );
};

export default CreateHomework;
