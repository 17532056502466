import { useEffect, useState } from "react";
import {
  invokeUserRefetch,
  setCurrentQuizProgress,
} from "../../../store/slices/storeSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { handleSubmitQuizToServer } from "../utils/handleSubmitQuizToServer";
import { ErrorTags, reportAppError } from "../../../errorReporter";
import useAssessmentController from "../../../hooks/useAssessmentController";
import { useQueryClient } from "@tanstack/react-query";
import { LessonDataI, QuestionData } from "../typesAndEnums";

interface QuizData {
  score?: number;
  maxScore?: number;
  percentage?: number;
  isEnded: boolean;
  isStarted: boolean;
  isExpired: boolean;
  remainingTime: number;
  questions: QuestionData[];
}

const useQuizHandler = ({ lessonData }: { lessonData: LessonDataI }) => {
  const [quizData, setQuizData] = useState<QuizData>();
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { getResult } = useAssessmentController();
  const { currentQuizProgress } = useAppSelector((state) => state.store);

  const handleSubmitQuiz = async () => {
    const result = await getResult({
      userAnswers: currentQuizProgress,
      questions: lessonData?.quiz_questions,
    });

    await handleSubmitQuizToServer({
      lessonData,
      result,
      setIsSubmitPending,
      setSubmitError,
    });

    if (!submitError && !isSubmitPending) {
      dispatch(invokeUserRefetch());
      queryClient.invalidateQueries({
        queryKey: ["lesson"],
      });
      localStorage.removeItem(`quiz_${lessonData?.quiz_id}_start_date`);
      localStorage.removeItem(`quiz_${lessonData?.quiz_id}_user_answers`);
    }
  };

  const shuffleQuestions = (array: QuestionData[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleStartQuiz = () => {
    localStorage.setItem(
      `quiz_${lessonData?.quiz_id}_start_date`,
      `${Date.now()}`
    );
    setQuizData((prev) => ({ ...prev, isStarted: true }));
  };

  const calculateRemainigTime = () => {
    // Get the difference in milliseconds
    let differenceInMilliseconds = 0;

    if (startedTimestamp)
      differenceInMilliseconds = Math.abs(Date.now() - +startedTimestamp);

    // Convert the difference to seconds
    const differenceInSeconds = differenceInMilliseconds / 1000;

    return Math.ceil(lessonData?.quiz_duration - differenceInSeconds);
  };

  const getLastProgress = () => {
    const previousProgress = JSON.parse(
      localStorage.getItem(`quiz_${lessonData?.quiz_id}_user_answers`) ?? "{}"
    );

    if (previousProgress) {
      dispatch(
        setCurrentQuizProgress({
          ...previousProgress,
        })
      );
    }
  };

  const startedTimestamp = localStorage.getItem(
    `quiz_${lessonData?.quiz_id}_start_date`
  );
  const currentTimestamp = Date.now();
  const quizExpiryTimestamp = Date.parse(lessonData?.last_trial_date);

  const remainingTime = calculateRemainigTime();
  const quizExpired = currentTimestamp > quizExpiryTimestamp;
  let questionsData: QuestionData[] = lessonData?.quiz_randomize
    ? shuffleQuestions(lessonData?.quiz_questions)
    : lessonData?.quiz_questions;

  let maxScore = null;
  let score = null;
  let percentage = null;

  if (lessonData?.past_quiz_trials) {
    try {
      const qRes = JSON.parse(
        lessonData?.past_quiz_trials?.[0]?.raw_data[0] ?? "{}"
      );

      maxScore = +qRes.maxScore;
      score = +qRes.score;
      percentage = (+qRes.score / +qRes.maxScore) * 100;
      questionsData = questionsData.map((q) => ({
        ...q,
        userAnswer: qRes[q.question_id]?.answer,
        isCorrect: qRes[q.question_id]?.correct,
      }));
    } catch (error) {
      reportAppError({
        e: error as Error,
        info: "error while parsing past quiz trial data",
        errorTag: ErrorTags.LessonScreen,
      });
    }
  }

  useEffect(() => {
    getLastProgress();
    setQuizData({
      score,
      maxScore,
      percentage,
      isEnded: !!lessonData?.past_quiz_trials,
      isStarted: !!startedTimestamp && !quizExpired,
      isExpired: quizExpired && !lessonData?.past_quiz_trials,
      remainingTime,
      questions: questionsData,
    });
  }, [lessonData]);

  return {
    ...quizData,
    handleStartQuiz,
    handleSubmitQuiz,
    isSubmitPending,
    submitError,
  };
};

export default useQuizHandler;
