import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGoogleLogin } from "@react-oauth/google";
import { signInWithCustomToken } from "firebase/auth";
import { NavLink as Link } from "react-router-dom";
import GoogleIcon from "../../assets/images/google-icon.svg";
import { endpoints } from "../../hooks/endpoints";
import { useAppDispatch } from "../../store/hooks";
import { userData } from "../../store/slices/storeSlice";
import { auth } from "../App";

const LoginForm = () => {
  const [responseMsg, setResponseMsg] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginPending, setIsLoginPending] = useState(false);
  const [isGoogleWindowOpen, setIsGoogleWindowOpen] = useState(false);
  const [isLoginWithFacebook, setIsLoginWithFacebook] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    setIsLoginPending(true);
    const credentials = { username, password };
    fetch(endpoints.signIn, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log("User Authenticated");
        // console.log(result);
        if (result.token) {
          signInWithCustomToken(auth, result.firebase_token)
            .then((userCredential) => {
              // Signed in
              setIsLoginPending(false);
              const user = userCredential.user;
              console.log({ user });
              dispatch(userData(result));
              localStorage.setItem(
                `${window.location.hostname}_user`,
                JSON.stringify(result)
              );
              if (result.data_completed) navigate("/school-grades");
              else navigate("/complete-info");
              // ...
            })
            .catch((error) => {
              setIsLoginPending(false);
              const errorCode = error.code;
              const errorMessage = error.message;
              throw new Error(errorMessage);
              // ...
            });
          // console.log('LocalStorage: ', result)
        } else {
          setIsLoginPending(false);
          const erMsg = document.getElementById("ea-err-msg2");
          if (Object.keys(result)[0].includes("invalid_email")) {
            erMsg.classList.add(
              "alert",
              "alert-danger",
              "mt-3",
              "align-self-center",
              "text-center",
              "rounded-ea"
            );
            erMsg.classList.remove("invisible");
            setResponseMsg("الإيميل مش صح");
          } else if (Object.keys(result)[0].includes("invalid_username")) {
            erMsg.classList.add(
              "alert",
              "alert-danger",
              "mt-3",
              "align-self-center",
              "text-center",
              "rounded-ea"
            );
            erMsg.classList.remove("invisible");
            setResponseMsg("الرقم مش صح");
          } else if (Object.keys(result)[0].includes("password")) {
            erMsg.classList.add(
              "alert",
              "alert-danger",
              "mt-3",
              "align-self-center",
              "text-center",
              "rounded-ea"
            );
            erMsg.classList.remove("invisible");
            setResponseMsg("كلمة السر مش صح");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
        const erMsg = document.getElementById("ea-err-msg2");
        erMsg.classList.add(
          "alert",
          "alert-danger",
          "mt-3",
          "align-self-center",
          "text-center",
          "rounded-ea"
        );
        erMsg.classList.remove("invisible");
        setResponseMsg("حدث خطأ");
        setIsLoginPending(false);
      });
  };

  const validateGoogleTokenOnServer = (code, currentPageUrl) => {
    setIsLoginPending(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code,
      url: currentPageUrl,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(endpoints.googleSocialLogin, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "credentialResponse");
        if (result.token) {
          signInWithCustomToken(auth, result.firebase_token)
            .then((userCredential) => {
              // Signed in
              setIsLoginPending(false);
              const user = userCredential.user;
              console.log({ user });
              dispatch(userData(result));
              localStorage.setItem(
                `${window.location.hostname}_user`,
                JSON.stringify(result)
              );
              setIsGoogleWindowOpen(false);
              if (result.data_completed) navigate("/school-grades");
              else navigate("/complete-info");
              // ...
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              throw new Error(errorMessage);
              // ...
            });
        } else {
          const erMsg = document.getElementById("ea-err-msg2");
          erMsg.classList.add(
            "alert",
            "alert-danger",
            "mt-3",
            "align-self-center",
            "text-center",
            "rounded-ea"
          );
          erMsg.classList.remove("invisible");
          setResponseMsg("الإيميل مش صح");
          setIsGoogleWindowOpen(false);
          setIsLoginPending(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setResponseMsg("حدث خطأ");
        setIsGoogleWindowOpen(false);
      });
  };

  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (GoogleResponse) => {
      console.log("Login Success:", GoogleResponse);
      validateGoogleTokenOnServer(GoogleResponse.code, window.location.origin);
    },
    onError: (error) => {
      console.log("Login Failed:", error);
      setResponseMsg("حدث خطأ");
      setIsGoogleWindowOpen(false);
    },
    onNonOAuthError: () => {
      setIsGoogleWindowOpen(false);
    },
  });

  useEffect(() => {
    if (window.location.href.indexOf("code") !== -1) {
      const fbCode = window.location.href.split("code=")[1];
      if (!fbCode) return;
      setIsLoginPending(true);
      setIsLoginWithFacebook(true);
      fetch(endpoints.facebookSocialLogin, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fbCode,
          redirectUri: `${window.location.origin}${window.location.pathname}`,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result, "credentialResponse");
          setIsLoginPending(false);
          setIsLoginWithFacebook(false);
          if (result.token) {
            dispatch(userData(result));
            localStorage.setItem(
              `${window.location.hostname}_user`,
              JSON.stringify(result)
            );
            navigate("/school-grades");
          }
        })
        .catch((err) => {
          console.log(err.message);
          setResponseMsg("حدث خطأ");
          setIsLoginWithFacebook(false);
          setIsLoginPending(false);
        });
    }
  }, [window.location.pathname]);

  return (
    <form
      id="the-form-div"
      onSubmit={handleLoginSubmit}
      className="m-3 bg-transparent"
    >
      <div className="form-floating mb-3 text-ea-primary">
        <input
          type="text"
          className="form-control
            rounded-0
            text-dark
            bg-transparent
            border-bottom-3
            border-top-0
            border-start-0
            border-end-0"
          placeholder="name@example.com"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <label htmlFor="floatingInput">التلفون او الإيميل</label>
      </div>
      <div className="form-floating mb-3 text-ea-primary">
        <input
          type="password"
          className="form-control
            rounded-0
            text-dark
            bg-transparent
            border-bottom-3
            border-top-0
            border-start-0
            border-end-0"
          placeholder="***"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <label htmlFor="floatingInput">كلمة السر</label>
      </div>
      <div className="social-login-container">
        <button
          type="button"
          className="btn google-login rounded-ea text-light social-login-btns"
          disabled={isGoogleWindowOpen}
          style={{
            pointerEvents: isGoogleWindowOpen ? "none" : "auto",
            opacity: isGoogleWindowOpen ? "0.5" : "1",
          }}
          onClick={() => {
            loginWithGoogle();
            setIsGoogleWindowOpen(true);
          }}
        >
          الدخول بجوجل{" "}
          <img
            style={{ margin: "0 5px", justifySelf: "center" }}
            src={GoogleIcon}
            alt="google"
          />
        </button>
        {/* <a
          style={{
            pointerEvents: isLoginWithFacebook ? "none" : "auto",
            opacity: isLoginWithFacebook ? "0.5" : "1",
          }}
          className="btn facebook-login rounded-ea text-light social-login-btns"
          href={`https://www.facebook.com/v20.0/dialog/oauth?client_id=3331531460486266&redirect_uri=${window.location.origin}${window.location.pathname}`}
        >
          الدخول بفيسبوك{" "}
          <img
            style={{ margin: "0 5px", justifySelf: "center" }}
            src={FacebookIcon)}
            alt="facebook"
          />
        </a> */}
      </div>
      {!isLoginPending && (
        <button
          type="submit"
          className="btn ea-btn rounded-ea text-light w-100"
        >
          دخول
        </button>
      )}
      {isLoginPending && (
        <button
          disabled
          className="btn ea-btn rounded-ea text-light w-100"
          type="submit"
        >
          <span
            className="spinner-border spinner-border-sm ms-2 me-2"
            role="status"
            aria-hidden="true"
          ></span>
          جاري الدخول...
        </button>
      )}
      <div id="ea-err-msg2" className="invisible" role="alert">
        {responseMsg}
      </div>
      <p className="my-2 d-flex">
        <Link
          to="/password-reset"
          className="text-ea-primary m-auto"
          style={{ textDecoration: "none" }}
        >
          نسيت كلمة السر
        </Link>
      </p>
    </form>
  );
};

export default LoginForm;
