import extractVideoId from "./extractVideoId";

const createPreview = (e) => {
    const iframeExist = document.getElementById(e.target.id + "_preview");

    if (e.target.value === "") {
      if(iframeExist){
        iframeExist.remove()
      }
      return
    }

    // Define the necessary variables
    const videoURL = e.target.value;

    if (iframeExist) {
      iframeExist.src = `https://www.youtube.com/embed/${extractVideoId(
        videoURL
      )}`;
    } else {
      // Create a new iframe element
      const iframe = document.createElement("iframe");

      // Set the attributes for the iframe
      iframe.id = e.target.id + "_preview";
      iframe.style.minHeight = "260px";
      iframe.className = "responsive-vid-preview";
      iframe.src = `https://www.youtube.com/embed/${extractVideoId(videoURL)}`;
      iframe.title = "YouTube video player";
      iframe.frameBorder = "0";
      iframe.allow =
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share";
      iframe.allowFullscreen = true;

      // Append the iframe to a container or the document body
      // For example, assuming you have a container with the id "iframeContainer"
      const container = document.getElementById(e.target.id + "_preview_div");
      container.appendChild(iframe);
    }
  };

  export default createPreview