import { NavLink as Link } from "react-router-dom";

const MultipleDeviceNotice = () => {

    return (
        <div className="not-found container">
            <div className="px-3 d-flex">
                <img
                    className="m-auto multiple-devices-notification"
                    src="https://firebasestorage.googleapis.com/v0/b/eliteacademyeg-a7552.appspot.com/o/Yellow%20Black%20Bold%20Attention%20Message%20Instagram%20Post%20(1).svg?alt=media"
                    alt=""
                />
            </div>
            <div className="d-flex">
                <Link to="/" className="m-auto text-ea">
                    <p className="fw-bold fs-3 text-ea">الرجوع للصفحة الرئيسية...</p>
                </Link>
            </div>
        </div>
    );
}
 
export default MultipleDeviceNotice;