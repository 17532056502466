import React from "react";

const VimeoVideo = ({ videoUrl }) => {
  return (
    <>
      <iframe
        src={videoUrl}
        // src="https://player.vimeo.com/video/926842113?h=2f93ab1465"
        // width={720}
        // height={360}
        frameBorder={0}
        style={{ width: "100%", height: "500px", pointerEvents: "auto" }}
        allow="fullscreen; picture-in-picture"
        allowFullScreen=""
      />
      {/* <p>
        <a href="https://vimeo.com/926842113">Chapter (5) Lesson (3) 2024</a>{" "}
        from <a href="https://vimeo.com/user217198900">Mohamed Galal</a> on{" "}
        <a href="https://vimeo.com">Vimeo</a>.
      </p> */}
    </>
  );
};

export default VimeoVideo;
