import { useEffect, useState } from "react";

const useCheckIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  useEffect(() => {
    const userAgent =
      navigator.userAgent ||
      navigator.vendor ||
      //@ts-ignore
      window?.opera;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
    setIsIOS(/iPad|iPhone|iPod/i.test(navigator.userAgent));
  }, []);
  return { isMobile, isIOS };
};

export default useCheckIsMobile;
