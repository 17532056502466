import React, { useState } from "react";
import { getFileUrl } from "../../hooks/utils";

function QuestionImg({
  src,
  alt,
  maxHeight = 1000,
  classStyles = "m-auto my-1 rounded-3 img-fluid",
  border = "",
}) {
  const [imageKey, setImageKey] = useState(0); // Add a key state

  const handleImageError = () => {
    // Image failed to load, so let's attempt to reload it
    setImageKey(imageKey + 1); // Change the key to force a re-render
  };

  if (imageKey > 5 && src.indexOf('drive.google.com') > -1) {
    return (
      <a href={src} target="_blank" className="btn ea-btn rounded-ea mx-auto" rel="noreferrer">
        حدث خطأ في تحميل الصورة إضغط هنا
      </a>
    );
  }

  return (
    <img
      width="100%"
      className={classStyles}
      style={{ maxHeight: maxHeight, border: border }}
      src={decodeURIComponent(src)}
      alt={alt}
      loading="lazy"
      onError={handleImageError}
      key={imageKey} // Use the key to force a re-render when needed
    />
  );
}

export default QuestionImg;
