/**
 * This page is cloned from the invoices page so it might
 * have similar naming conventions to the invoices page
 *
 */

import { NavLink as Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Animation from "../Animation";
import { endpoints } from "../../hooks/endpoints";

import { getToken, reorderForPagination } from "../../hooks/utils";
import { useAppSelector } from "../../store/hooks";
import { Lesson } from "../../store/slices/storeSlice";

interface AssessmentReportI {
  ID: string;
  answers: string;
  date_submitted: string;
  grade: string;
  homework: string;
  homework_id: string;
  lesson_id: string;
  parent_phone: string;
  post_date: string;
  raw_data: string;
  student: string;
  student_id: string;
  student_name: string;
  student_phone: string;
  title: string;
}

const QuizResults = () => {
  const [invoicesStart, setInvoicesStart] = useState(0);
  const [allInvoices, setAllInvoices] = useState<AssessmentReportI[]>();
  const [invoices, setInvoices] = useState<AssessmentReportI[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [displayedReport, setDisplayedReport] = useState<
    AssessmentReportI[] | null
  >(null);
  // const [cats, setCats] = useState();
  const [currentCat, setCurrentCat] = useState();
  const [groups, setGroups] = useState();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  // const [teacherData, setTeacherData] = useState({ name: "", subject: "" });
  const lessonRef = useRef<HTMLSelectElement>();
  const gradeTypeRef = useRef();

  const { userProfileData, portal } = useAppSelector((state) => state.store);
  const teacherData = {
    name: userProfileData.teacher_name,
    subject: userProfileData.teacher_subject,
  };

  const cats = portal?.cats?.filter((cat) => cat.parent !== 0);
  const lessons = portal?.data;

  const formatPhoneNumber = (phoneNumberString) => {
    return phoneNumberString?.replaceAll(" ", "").split("-");
  };
  const getStudentScore = async (invoice, phone) => {
    const data = await JSON.parse(invoice.raw_data);
    window.open(
      `https://api.whatsapp.com/send?phone=2${phone}&text=السادة%20أولياء%20أمر%20الطالب/ة%20${
        invoice.student_name
      }%20,%20نتيجة%20${
        gradeTypeRef?.current?.value !== "grades" ? "الواجب" : "الاختبار"
      }%20للحصة%20${
        "*" +
        lessons.filter((item) => +item.ID === +lessonRef.current.value)[0]
          .post_title +
        "*"
      }%20هي%20${data.score + " / " + data.maxScore}%20,%20${
        teacherData.name
      }%20مادة%20${teacherData.subject}`,
      "_blank"
    );
  };

  const getFilteredResults = (arr: AssessmentReportI[]) => {
    const newArr = [
      ...arr.filter(
        (invoice: AssessmentReportI) =>
          invoice.lesson_id === lessonRef.current.value
      ),
      ...arr.filter((invoice: AssessmentReportI) => !invoice.lesson_id),
    ];
    // const lastArr = [];
    // for (let i = 0; i < newArr.length; i++) {
    //   if (!lastArr.some((item) => item.lesson_id === newArr[i].lesson_id)) {
    //     lastArr.push(newArr[i]);
    //   }
    // }
    return newArr;
  };

  const getGrades = (e) => {
    e.preventDefault();
    setInvoicesStart(0);
    if (!lessonRef.current.value || gradeTypeRef?.current?.value === "-")
      return;
    setIsPending(true);
    fetch(
      endpoints.qzHwResultTeacehr +
        `?lesson=${lessonRef.current.value}&gradetype=${gradeTypeRef?.current?.value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setIsPending(false);
        console.log(data);
        setAllInvoices(getFilteredResults(data));
        setInvoices(getFilteredResults(data));
        setDisplayedReport(
          reorderForPagination({
            arr: getFilteredResults(data),
            currentPage: 1,
            pageCount: 10,
          })
        );
        if (getFilteredResults(data).length === 0) {
          setError("لا يوجد نتائج لهذا الإختبار");
        } else {
          setError(null);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (invoices)
      setDisplayedReport(
        reorderForPagination({
          arr: invoices,
          currentPage: currentPage,
          pageCount: 10,
        })
      );
  }, [currentPage]);

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <div className="table-responsive">
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            عرض النتائج
          </h3>
          <form onSubmit={(e) => getGrades(e)}>
            <select
              onChange={(e) => {
                lessonRef.current.value = null;
                gradeTypeRef.current.value = "-";
                setCurrentCat(e.target.value);
              }}
              className="form-select w-75 my-2"
              aria-label="Default select example"
            >
              <option value="-" selected>
                القسم
              </option>
              {cats &&
                cats?.map((cat) => (
                  <option value={cat.slug}>
                    {cat.name.indexOf("|") !== -1
                      ? cat.name.split("|")[0]
                      : cat.name}
                  </option>
                ))}
            </select>
            <select
              ref={lessonRef}
              className="form-select w-75 my-2"
              aria-label="Default select example"
            >
              <option value="all" selected>
                الحصة
              </option>
              {lessons &&
                lessons
                  ?.filter(
                    (lesson) =>
                      lesson.term_taxonomy_id ==
                      cats.filter((cat) => cat.slug == currentCat)?.[0]
                        ?.term_taxonomy_id
                  )
                  ?.map((lesson) => (
                    <option value={lesson.ID}>{lesson.post_title}</option>
                  ))}
            </select>
            <select
              ref={gradeTypeRef}
              className="form-select w-75 my-2"
              aria-label="Default select example"
            >
              <option value="-" selected>
                نوع الإمتحان
              </option>
              <option value="grades">كويز</option>
              <option value="hwgrades">واجب</option>
            </select>
            {!isPending && (
              <input
                type="submit"
                className="btn ea-btn rounded-ea my-2"
                value="عرض"
              />
            )}
            {isPending && (
              <button
                className="btn ea-btn rounded-ea my-2"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                عرض...
              </button>
            )}
          </form>
          {invoices && (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">الطالب</th>
                    <th scope="col">رقم الطالب</th>
                    <th scope="col">إرسال التقرير لولي الأمر</th>
                    <th scope="col">الدرجة</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">تفاصيل</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices &&
                    lessonRef.current.value &&
                    displayedReport?.map((invoice, index) => (
                      <tr key={invoice.ID}>
                        <td scope="row">
                          {index + 1 + (currentPage - 1) * 10}
                        </td>
                        <td scope="row" style={{ whiteSpace: "nowrap" }}>
                          {invoice.student_name}
                        </td>
                        <td scope="row">{invoice.student_phone}</td>
                        <td scope="row" style={{ whiteSpace: "nowrap" }}>
                          {formatPhoneNumber(invoice.parent_phone)?.map(
                            (phone) => (
                              <button
                                type="button"
                                onClick={() => getStudentScore(invoice, phone)}
                                style={{
                                  maxHeight: "30px",
                                  padding: "3px 10px",
                                  whiteSpace: "nowrap",
                                }}
                                className="pt-0 btn ea-btn rounded-ea mx-1"
                              >
                                {phone}
                              </button>
                            )
                          )}
                        </td>
                        <td>{invoice.grade}</td>
                        <td>{invoice.date_submitted}</td>
                        <td>
                          <Link
                            to={`/grades/${invoice.ID}`}
                            style={{
                              maxHeight: "30px",
                              padding: "3px 8px",
                            }}
                            className="pt-0 btn ea-btn rounded-ea"
                          >
                            تفاصيل
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="d-flex">
            {invoices && currentPage * 10 < invoices?.length && (
              <button
                onClick={() => setCurrentPage((prev) => prev + 1)}
                className="m-1 btn ea-btn rounded-ea"
              >
                التالي
              </button>
            )}
            {invoices && currentPage > 1 && (
              <button
                onClick={() => setCurrentPage((prev) => prev - 1)}
                className="m-1 btn ea-btn rounded-ea"
              >
                السابق
              </button>
            )}
          </div>

          {/* <div className="d-flex">
            {invoices && invoicesStart + 10 < invoices.length && (
              <button
                onClick={() => setInvoicesStart((prev) => prev + 10)}
                className="m-1 btn ea-btn rounded-ea"
              >
                التالي
              </button>
            )}
            {invoices && invoicesStart > 0 && (
              <button
                onClick={() => setInvoicesStart((prev) => prev - 10)}
                className="m-1 btn ea-btn rounded-ea"
              >
                السابق
              </button>
            )}
          </div> */}
          {error && <h4>{error}</h4>}
        </div>
      </div>
    </Animation>
  );
};

export default QuizResults;
