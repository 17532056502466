import { useEffect, useState } from "react";
import { PlaybackMenu } from "./Playback";

const Quality = ({
  isVisible,
  updatePlaybackQuality,
  openMenu,
  setOpenMenu,
}: {
  isVisible: boolean;
  updatePlaybackQuality: (e: any) => void;
  openMenu: string;
  setOpenMenu: (val: PlaybackMenu) => void;
}) => {
  const qualityMapper = {
    medium: "360p",
    large: "480p",
    hd720: "720p",
    hd1080: "1080p",
  };

  const [playbackQuality, setPlaybackQuality] = useState<string>(
    qualityMapper["medium"]
  );

  const handleSelectorChange = (quality: string) => {
    setPlaybackQuality(qualityMapper[quality]);
    updatePlaybackQuality(quality);
    setOpenMenu(PlaybackMenu.closed);
  };

  const handleOpenMenu = () => {
    openMenu === PlaybackMenu.quality
      ? setOpenMenu(PlaybackMenu.closed)
      : setOpenMenu(PlaybackMenu.quality);
  };

  useEffect(() => {
    setPlaybackQuality(qualityMapper["large"]);
  }, []);

  if (!isVisible) return <></>;

  return (
    <div className="ea-playback-quality">
      <span onClick={handleOpenMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-gear-fill mx-1"
          viewBox="0 0 16 16"
        >
          <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
        </svg>
        <span style={{padding: 0, margin: 0}}>{playbackQuality}</span>
      </span>
      <ul className={openMenu === PlaybackMenu.quality ? "selector-visible" : ""}>
        <li onClick={() => handleSelectorChange("medium")}>360p</li>
        <li onClick={() => handleSelectorChange("large")}>480p</li>
        <li onClick={() => handleSelectorChange("hd720")}>720p</li>
        <li onClick={() => handleSelectorChange("hd1080")}>1080p</li>
      </ul>
    </div>
  );
};

export default Quality;
